import { css } from "goober";

export const cssContainer = () => css`
  --radii: var(--br);
  display: none;
  padding: var(--sp-8);
  background-color: var(--shade7);
  border-bottom-left-radius: var(--br);
  border-bottom-right-radius: var(--br);
`;
