import { css } from "goober";

export const cssContainer = () => css`
  display: none;

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--sp-3);
    padding: var(--sp-3);
  }

  .group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
    padding: var(--sp-3);
    padding-block-end: 0;
    background: var(--shade8);
    border-radius: var(--br);

    .title {
      color: var(--shade1);
      background: var(--shade1-15);
      padding: var(--sp-0_5) var(--sp-2);
      width: fit-content;
      border-radius: var(--br-sm);

      :not([data-phase="BANNING"]) & {
        color: var(--blue);
        background: var(--blue-15);
      }
      [data-phase="BANNING"] & {
        color: var(--red);
        background: var(--red-15);
      }
    }

    .waiting {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: var(--sp-3);
      justify-content: center;
      align-items: center;
      padding-block-start: 2rem;
      inset: 0;

      svg {
        width: 7rem;
        height: 7rem;
      }
    }

    &[data-active="false"] {
      .suggestions-list {
        opacity: 0;
        pointer-events: none;
      }
      .search-container {
        opacity: 0.25;
        pointer-events: none;
      }
    }
    &[data-active="true"] {
      .waiting {
        display: none;
      }
    }
  }

  .search-container {
    width: 100%;
  }

  .suggestions-list {
    height: 24.25rem;
    padding-block-end: 3rem;
    overflow-y: scroll;
    -webkit-mask-image: linear-gradient(to top, transparent, black 7rem);

    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  .duo {
    .champ-portrait {
      width: var(--sp-7);

      &:nth-child(1) {
        translate: 20% -20%;
      }
      &:nth-child(2) {
        translate: 20% -20%;
        translate: -20% 20%;
        box-shadow: 0 0 0 2px var(--shade8);
      }
    }
  }
`;
