import { css } from "goober";

export const benchCSS = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -1rem;

  &[data-flow="LIVE"] {
    display: none;
  }

  > * {
    display: grid;
    gap: var(--sp-0_5);
  }

  .caption {
    color: var(--shade1);
  }

  .bench-list {
    display: flex;
    align-items: center;
    gap: var(--sp-0_5);
  }

  li {
    --wr-color: var(--shade3);
  }
  li[data-has-champion="true"] {
    cursor: pointer;
    transition: filter var(--transition);

    &:not([data-is-playable="true"]) {
      pointer-events: none;
    }
  }

  li[data-has-winrate="true"] {
    .winrate {
      opacity: 1;
    }

    .bench-item::before {
      background: var(--wr-color);
      opacity: 0.4;
    }

    &:not([data-high-winrate="true"]) {
      opacity: 0.75;
    }

    &:hover {
      img {
        filter: brightness(1.2);
      }
      .bench-item::before {
        opacity: 1;
        animation: border-highlight 3s linear forwards infinite;
      }
    }
  }

  li[data-is-user="true"] {
    pointer-events: none;
    cursor: default;

    .bench-item::before {
      background: var(--yellow);
      opacity: 1;
    }
  }

  .bench-item {
    position: relative;
    padding: 2px;
    background: var(--shade10);
    border-radius: var(--br);
    transform: translateY(-5%);

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--shade7);
      border-radius: var(--br);
    }
    .champ-img {
      width: var(--sp-11);
      border: 1px solid var(--shade10);
      background: var(--shade6);
      border-radius: var(--br-sm);
    }
  }

  .winrate {
    display: block;
    color: var(--wr-color);
    text-align: center;
    opacity: 0;
    translate: 0 -15%;
  }

  .reroll-buttons {
    display: flex;
    gap: var(--sp-1);
  }
  .reroll-button,
  .reroll-and-keep-button {
    position: relative;
    overflow: visible;
    color: var(--white);

    &[data-has-rerolls="false"] {
      pointer-events: none;
      opacity: 0.38;
    }

    &:hover {
      --bg: var(--primary);
      --border: var(--primary);
    }
  }
  .reroll-button {
    .reroll-count {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      width: var(--sp-5);
      height: var(--sp-5);
      background: var(--shade9);
      border-radius: 50%;
      transform: translate(35%, -35%);
    }
  }
  .reroll-and-keep-button {
    --bg: var(--blue-15);
    --border: var(--blue-50);

    &:hover {
      --bg: var(--blue-50);
      --border: var(--blue);
      box-shadow: inset 0 0 0 1px var(--blue);
    }

    svg {
      width: var(--sp-6);
      height: var(--sp-6);
      fill: white;
    }
  }
`;
