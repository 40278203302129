import {
  cssContainer,
  cssSkillOrder,
  cssSkillOrderTitle,
} from "@/game-lol/components/in-game-template-skill-order.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  <div class="${cssSkillOrderTitle()}">
    <p class="type-body2-form--active">{{title}}</p>
    <span class="${cssSkillOrder()}">
      {{#skillOrder}}
      <div class="skill" style="{{style}}">
        <span class="type-caption--bold">{{content}}</span>
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.61804 0L0.666626 0.94L3.75704 4L0.666626 7.06L1.61804 8L5.66663 4L1.61804 0Z"
            fill="currentColor"
          />
        </svg>
      </div>
      {{/skillOrder}}
    </span>
  </div>
  <div class="${cssContainer()}">
    {{#col}}
    <div class="col">
      {{#row}}
      <div
        class="row type-mini"
        style="{{style}}"
        data-active="{{isActive}}"
        data-tooltip="{{tooltip}}"
      >
        <span>{{content}}</span>
      </div>
      {{/row}}
    </div>
    {{/col}}
  </div>
`;

export default template;
