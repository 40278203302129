import { cssContainer } from "@/game-lol/components/in-game-template-preferred-bans.style.mjs";
import html from "@/util/html-template.mjs";

export const preferredBans = html`
  <div class="${cssContainer()}">
    <div>
      <h4 class="section-title type-article-headline">{{title}}</h4>
      <p class="section-subtitle type-caption">{{subtitle}}</p>
    </div>
    <ol class="preferred-bans-list">
      {{#list}}
      <li
        onclick="{{onClick}}"
        oncontextmenu="{{onContextMenu}}"
        data-empty="{{empty}}"
        data-teammate="{{teammate}}"
        data-tip="{{tooltip}}"
        data-place="{{tooltipPlacement}}"
        style="{{style}}"
      >
        <img width="60" class="champ-img" height="60" src="{{image}}" />
        <div class="close-icon">{{{closeIcon}}}</div>
        <div class="warning-icon">{{{warningIcon}}}</div>
      </li>
      {{/list}}
    </ol>
  </div>
`;
