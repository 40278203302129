import { cssButton, cssButtonGrp } from "clutch/src/Button/Button.jsx";

import buildItems from "@/game-lol/components/in-game-template-build-items.mjs";
import {
  cssBuildListItem,
  cssContainer,
  cssRightColumn,
  cssSelectedBuild,
} from "@/game-lol/components/in-game-template-builds.style.mjs";
import runeTree from "@/game-lol/components/in-game-template-runes.mjs";
import skillOrder from "@/game-lol/components/in-game-template-skill-order.mjs";
import summonerSpells from "@/game-lol/components/in-game-template-summoner-spells.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  <div class="cols builds ${cssContainer()}">
    <div class="builds-list">
      {{#buildOptions}}
      <div
        class="${cssButtonGrp()} button-group builds-options"
        data-block-group="{{blockOptions}}"
      >
        {{#options}}
        <button
          onclick="{{onClick}}"
          class="${cssButton()}"
          data-tip="{{tooltip}}"
          data-active="{{isActive}}"
          data-disabled="{{disabled}}"
        >
          <span class="type-form--button">{{title}}</span>
        </button>
        {{/options}}
      </div>

      {{/buildOptions}} {{#cta}}
      <div class="cta">
        <h5 class="type-subtitle1">{{title}}</h5>
        <p class="type-caption color-shade1">{{description}}</p>
      </div>
      {{/cta}}
      <div class="builds-playstyle">
        {{#playstyleBuilds}}
        <div class="group-title" style="{{style}}">
          {{#icon}}
          <span class="icon" data-tooltip="{{label}}">{{{iconSvg}}}</span>
          {{/icon}}
          <p class="type-callout--semi">{{title}}</p>
        </div>
        <ol class="build-list">
          {{#buildsList}}
          <li
            class="${cssBuildListItem()} build-list-item"
            data-selected="{{isSelected}}"
            data-skeleton="{{isLoadingSkeleton}}"
            onclick="{{initiateImport}}"
            style="{{style}}"
          >
            <div>
              <div class="title-container">
                <p class="type-callout--semi title">{{{title}}}</p>
                {{{unlock}}}
              </div>
              <span class="type-caption subtitle">{{{subtitle}}}</span>
            </div>
            <div class="flex align-center gap-sp-1 build">
              <div class="runes">
                <img src="{{keystone}}" width="28" height="28" />
                <div class="secondary">
                  <img src="{{secondaryTree}}" width="12" height="12" />
                </div>
              </div>
              {{#mainItems}}
              <img src="{{src}}" class="item" width="24" height="24" />
              {{/mainItems}}
              <div class="winrate" style:--winrate-color="{{winrateColor}}">
                <span class="type-caption--bold">{{winrate}}</span>
              </div>
            </div>
          </li>
          {{/buildsList}}
        </ol>
        {{/playstyleBuilds}}
      </div>
      <div class="builds-matchup">
        {{#matchupBuilds}}
        <div class="group-title">
          {{#icon}}
          <span class="icon" data-tooltip="{{label}}">{{{iconSvg}}}</span>
          {{/icon}}
          <p class="type-callout--semi">{{title}}</p>
        </div>
        <ol>
          {{#buildsList}}
          <li
            data-build-key="{{id}}"
            class="${cssBuildListItem()} build-list-item"
            data-selected="{{isSelected}}"
            onclick="{{initiateImport}}"
          >
            <div class="matchup-title">
              <div class="avatar">
                <img
                  class="avatar__image"
                  src="{{champAvatar}}"
                  alt="matchup champ avatar"
                />
              </div>
              <p class="type-callout--semi title">
                <span class="top">
                  <span>{{tVersus}}</span><strong>{{{title}}}</strong>
                </span>
                <span class="bottom">
                  {{subtitle}}
                  <svg
                    data-show="{{isLowCount}}"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.4 5.4c1.82-1.82 2.3-2.242 2.844-2.467.544-.225 1.18-.266 3.756-.266 2.574 0 3.212.04 3.755.266.544.225 1.024.647 2.844 2.467 1.821 1.82 2.242 2.3 2.468 2.844.225.544.266 1.181.266 3.756 0 2.575-.041 3.212-.266 3.756-.226.543-.647 1.023-2.468 2.844-1.82 1.82-2.3 2.242-2.844 2.467-.543.225-1.18.266-3.755.266s-3.212-.041-3.756-.266c-.543-.225-1.023-.647-2.844-2.467-1.82-1.821-2.242-2.301-2.467-2.844-.225-.544-.267-1.181-.267-3.756 0-2.575.042-3.212.267-3.756.225-.543.646-1.023 2.467-2.844ZM3.75 3.75C5.436 2.064 6.28 1.221 7.351.777 8.423.333 9.615.333 12 .333c2.384 0 3.576 0 4.648.444 1.072.444 1.915 1.287 3.601 2.973s2.53 2.53 2.973 3.601c.444 1.072.444 2.265.444 4.649s0 3.576-.444 4.648c-.444 1.073-1.287 1.916-2.973 3.602s-2.529 2.529-3.6 2.973c-1.073.444-2.265.444-4.65.444-2.384 0-3.576 0-4.648-.444-1.072-.444-1.915-1.287-3.6-2.973-1.687-1.686-2.53-2.53-2.974-3.602C.333 15.576.333 14.384.333 12s0-3.577.444-4.649 1.287-1.915 2.973-3.6Zm6.791 12.625a1.458 1.458 0 1 0 2.917 0 1.458 1.458 0 0 0-2.917 0Zm.31-4.01a1.15 1.15 0 0 0 2.297 0l.237-4.742a1.387 1.387 0 1 0-2.77 0l.236 4.743Z"
                      fill="#DD344A"
                    />
                  </svg>
                </span>
              </p>
            </div>
            <div class="flex align-center gap-sp-1">
              <div class="runes">
                <img src="{{keystone}}" width="28" height="28" />
                <div class="secondary">
                  <img src="{{secondaryTree}}" width="12" height="12" />
                </div>
              </div>
              {{#mainItems}}
              <img src="{{src}}" class="item" width="24" height="24" />
              {{/mainItems}}
              <div class="winrate" style:--winrate-color="{{winrateColor}}">
                <span class="type-caption--bold">{{winrate}}</span>
              </div>
            </div>
          </li>
          {{/buildsList}}
        </ol>
        {{/matchupBuilds}}
      </div>
      <div class="builds-probuilds">
        {{#competitiveBuilds}}
        <div class="group-title" data-missing-data="{{dataMissing}}">
          {{#icon}}
          <span class="icon" data-tooltip="{{label}}">{{{iconSvg}}}</span>
          {{/icon}}
          <p class="type-callout--semi">{{title}}</p>
        </div>
        <ol>
          {{#buildsList}}
          <li
            class="${cssBuildListItem()} build-list-item probuild"
            data-selected="{{isSelected}}"
            data-has-team="{{hasTeam}}"
            data-is-old-patch="{{isOldPatch}}"
            onclick="{{initiateImport}}"
          >
            <div class="flex align-center gap-sp-2">
              <div class="pro-img">
                <img
                  src="{{proImg}}"
                  class="headshot"
                  width="28"
                  height="28"
                  loading="lazy"
                />
              </div>
              <div>
                <p class="type-callout--semi title">{{{title}}}</p>
                <span class="type-caption subtitle">{{{timestamp}}}</span>
              </div>
            </div>
            <div class="flex align-center gap-sp-2">
              <div class="flex align-center gap-sp-1">
                <div class="runes">
                  <img src="{{keystone}}" width="28" height="28" />
                  <div class="secondary">
                    <img src="{{secondaryTree}}" width="12" height="12" />
                  </div>
                </div>
                {{#mainItems}}
                <img src="{{src}}" class="item" width="24" height="24" />
                {{/mainItems}}
              </div>
              <div class="flex align-center gap-sp-1">
                <span class="vs type-caption">{{vs}}</span>
                <img
                  src="{{opponentImg}}"
                  class="round"
                  width="20"
                  height="20"
                  loading="lazy"
                />
              </div>
            </div>
          </li>
          {{/buildsList}}
        </ol>
        {{/competitiveBuilds}}
      </div>
      <div class="builds-probuilds">
        {{#proBuilds}}
        <div class="group-title" data-missing-data="{{dataMissing}}">
          {{#icon}}
          <span class="icon" data-tooltip="{{label}}">{{{iconSvg}}}</span>
          {{/icon}}
          <p class="type-callout--semi">{{title}}</p>
        </div>
        <ol>
          {{#buildsList}}
          <li
            class="${cssBuildListItem()} build-list-item probuild"
            data-selected="{{isSelected}}"
            data-fame="{{fame}}"
            data-has-team="{{hasTeam}}"
            onclick="{{initiateImport}}"
            data-skeleton="{{isLoadingSkeleton}}"
            data-is-old-patch="{{isOldPatch}}"
          >
            <div class="flex align-center gap-sp-2">
              <div style="position: relative;">
                <div class="pro-img">
                  <img
                    src="{{proImg}}"
                    class="headshot"
                    width="28"
                    height="28"
                    loading="lazy"
                  />
                </div>
              </div>
              <div>
                <p class="type-callout--semi title pro-name">{{{title}}}</p>
                <span class="type-overline subtitle">{{{timestamp}}}</span>
              </div>
            </div>
            <div class="flex align-center gap-sp-2">
              <div class="flex align-center gap-sp-1">
                <div class="runes">
                  <img src="{{keystone}}" width="28" height="28" />
                  <div class="secondary">
                    <img src="{{secondaryTree}}" width="12" height="12" />
                  </div>
                </div>
                {{#mainItems}}
                <img src="{{src}}" class="item" width="24" height="24" />
                {{/mainItems}}
              </div>
              <div class="flex align-center gap-sp-1">
                <span class="vs type-caption">{{vs}}</span>
                <img
                  src="{{opponentImg}}"
                  class="round"
                  width="20"
                  height="20"
                  loading="lazy"
                />
              </div>
            </div>
          </li>
          {{/buildsList}}
        </ol>
        {{/proBuilds}}
      </div>
    </div>
    <div class="${cssSelectedBuild()}">
      {{#premiumBenefits}}
      <div class="benefits">
        <ol>
          {{#sections}}
          <li style="{{style}}">
            <div class="number">
              <span class="type-caption--bold">{{number}}</span>
            </div>
            <p class="type-article-headline title">{{title}}</p>
            <p class="type-caption color-shade1 description">{{description}}</p>
          </li>
          {{/sections}}
        </ol>
        <a href="{{ctaLink}}" class="type-form--button">{{ctaCopy}}</a>
      </div>
      {{/premiumBenefits}} {{#selectedBuild}} {{#buildPatch}}
      <span class="build-patch type-caption--semi">{{text}}</span>
      {{/buildPatch}}
      <section class="build-body">
        <article class="build-left">
          <section class="runes">{{#runes}} ${runeTree} {{/runes}}</section>
          <section class="skill-order">
            {{#skillOrder}} ${skillOrder} {{/skillOrder}}
          </section>
        </article>
        <article class="${cssRightColumn()} build-right">
          {{#summonerSpells}} ${summonerSpells} {{/summonerSpells}}
          <section class="build-order">
            {{#buildItems}} ${buildItems} {{/buildItems}}
          </section>
        </article>
        {{#warning}} {{/warning}}
      </section>
      {{/selectedBuild}}
    </div>
  </div>
`;

export default template;
