import { css } from "goober";

export const cssRuneTreeContainer = () => css`
  --tree-color: var(--shade3);
  --keystone-size: var(--sp-13);
  --rune-size: var(--sp-8e);
  --shard-size: var(--sp-6e);
  --col-gap: var(--sp-2e);
  --row-gap: var(--sp-4e);
  --radii: 50%;

  display: flex;
  justify-content: center;
  gap: var(--sp-5e);

  .overlay & {
    --keystone-size: var(--sp-10e);
    --rune-size: var(--sp-7e);
    --shard-size: var(--sp-6e);
    flex-wrap: wrap;
    gap: var(--sp-6e);

    .rune-img {
      cursor: default;
    }

    @media (max-width: 1300px) {
      --keystone-size: var(--sp-8e);
      --rune-size: var(--sp-6e);
      --shard-size: var(--sp-4e);
      --col-gap: var(--sp-1e);
      --row-gap: var(--sp-2e);
      gap: var(--sp-2e);
    }
    @media (min-width: 1920px) {
      --keystone-size: var(--sp-12e);
      --rune-size: var(--sp-9e);
      --shard-size: var(--sp-8e);
    }
    @media (min-width: 1921px) {
      gap: var(--sp-10e);
    }
  }

  /* ~ RUNES ~ */
  .tree {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--row-gap);
  }
  .tree-row {
    display: flex;
    gap: var(--col-gap);

    &[data-keystones="true"] {
      --col-gap: 0;
      margin-bottom: -0.5rem;
    }
  }
  .shards {
    --tree-color: var(--yellow);
  }

  .rune-tree-img {
    width: var(--sp-7);
    height: var(--sp-7);
    padding: var(--sp-1);
    border-radius: 50%;
    border: 1px solid var(--tree-color);

    .rune-img {
      border: none;
    }

    &[data-active="false"] {
      --tree-color: transparent !important;
    }
  }

  .rune-container {
    position: relative;

    &[data-highlight="true"] {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        right: 2px;
        width: var(--sp-1);
        height: var(--sp-1);
        border-radius: 50%;
        background: var(--tree-color);
        z-index: 1;
        box-shadow: 0 0 0 2px var(--shade9);
      }
    }
  }
  .rune-img {
    --size: var(--rune-size);
    opacity: 0.35;
    width: var(--size);
    max-width: 100%;
    border: 2px solid var(--shade3);
    border-radius: var(--radii);
    filter: saturate(0);
    cursor: pointer;

    &[data-keystone="true"] {
      --size: var(--keystone-size);
      --radii: 0;
      border: none;
      box-shadow: none;
    }

    &[data-active="true"] {
      border-color: var(--tree-color);
      opacity: 1;
      filter: saturate(1);
    }
    &[data-active="false"] {
      transition: all var(--transition);

      &:hover {
        opacity: 1;
        filter: saturate(1);
        border-color: var(--tree-color);
        transform: translateY(-3%);
        filter: drop-shadow(0px 8px 10px black);
      }
    }

    &.shard {
      --tree-color: var(--yellow);
      --size: var(--shard-size);
      border-color: var(--shade5);

      &[data-active="true"] {
        border-color: var(--yellow);
      }
    }
  }
`;
