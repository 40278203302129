import { SummonerSpellOptions } from "@/game-lol/components/ChampionBuilds.style.jsx";
import html from "@/util/html-template.mjs";

const template = html`
  <section class="summoner-spells" data-show="{{show}}">
    {{#title}}
    <button
      class="section-title type-body2-form--active"
      onclick="{{onClick}}"
      style="{{style}}"
    >
      <span class="text">{{text}}</span>
      <span class="icon">{{{icon}}}</span>
    </button>
    {{/title}} {{#summonerSpellPages}}
    <div class="${SummonerSpellOptions()}">
      {{#list}}
      <button
        onclick="{{onclick}}"
        data-selected="{{selected}}"
        style="{{style}}"
        class="spells-set"
      >
        <div class="imgs">
          <img src="{{summonerSpellImg1}}" width="28" height="28" />
          <img src="{{summonerSpellImg2}}" width="28" height="28" />
        </div>
        {{#stats}}
        <span class="winrate type-caption--semi">{{winrate}}</span>
        <span class="games type-caption">{{games}}</span>
        {{/stats}}
      </button>
      {{/list}}
    </div>
    {{/summonerSpellPages}}
  </section>
`;

export default template;
