import { css } from "goober";

export const cssContainer = () => css`
  display: none;

  .section-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sp-1);
    padding: 0 var(--sp-1) 0 var(--sp-1_5);
    margin-bottom: var(--sp-2);
    color: var(--import-color);
    text-align: center;
    background: transparent;
    cursor: pointer;

    [data-flow="LIVE"] & {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--import-color);
      border-radius: var(--br-sm);
      opacity: 0.1;
      transition: opacity 0.15s ease;
    }

    &:hover {
      &::before {
        opacity: 0.2;
      }
    }

    svg {
      position: relative;
      width: var(--sp-6);
      height: var(--sp-6);
    }
  }

  .matchup-title {
    display: grid;
    grid-template-columns: var(--sp-8) auto;
    gap: var(--sp-2);
    place-items: center;

    .avatar {
      position: relative;
      width: var(--sp-8);
      height: var(--sp-8);

      &__image {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        border: var(--sp-0_5) solid var(--red);
      }
    }
    p {
      display: flex;
      flex-direction: column;

      .top {
        color: var(--white);
        font-weight: bold;

        span {
          color: var(--shade1);
          margin-right: var(--sp-1);
        }
      }
      .bottom {
        padding-left: 2px;
        color: var(--shade1);

        svg {
          margin-left: 2px;
          display: none;
          height: 11px;
          width: 11px;
          top: 2px;
          position: relative;

          &[data-show="true"] {
            display: inline-block;
          }
        }
      }
    }
  }

  /* ~Left column list of builds~ */
  .builds-list {
    --inset: var(--sp-4);
    height: 38rem;
    overflow-y: auto;
    background: var(--shade7-75);
    border-right: 1px solid var(--card-inset-border);
    mask-image: linear-gradient(to top, transparent 0%, black 5rem);

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--shade6);
    }

    [data-flow="LIVE"] & {
      height: 30rem;
    }

    .builds-options {
      padding: var(--inset);
      padding-block-end: 0;
    }

    .cta {
      text-align: center;
      padding-inline: var(--sp-8);
      padding-block-start: var(--sp-3);
    }

    .group-title {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
      color: var(--section-color, var(--shade1));
      padding: var(--sp-4) var(--inset) var(--sp-1) var(--inset);
      border-bottom: 1px solid var(--shade3-25);

      .icon {
        svg {
          height: var(--sp-3_5);
          width: var(--sp-3_5);
        }
      }
    }

    > *:last-child {
      padding-block-end: 6rem;
    }
  }

  .benefits {
    display: flex;
    flex-direction: column;
    gap: var(--sp-4);

    ol {
      display: flex;
      flex-direction: column;
      gap: var(--sp-4);
    }
    li {
      padding: var(--sp-6);
      background: var(--shade8);
      border-radius: var(--br);
      background-image: var(--bg-img);
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: 95%;

      img {
        width: 312px;
        height: auto;
      }
    }
    .number {
      display: grid;
      place-content: center;
      width: var(--sp-5);
      aspect-ratio: 1;
      margin-bottom: var(--sp-2);
      color: var(--shade10);
      background: var(--subscriber-gradient);
      border-radius: 50%;
    }
    .description {
      width: 34ch;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      background: var(--subscriber-gradient);
      border-radius: var(--br);
      text-align: center;
      height: var(--btn-height);
    }
  }
`;

export const cssBuildListItem = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sp-2) var(--inset) var(--sp-2) var(--inset);
  pointer-events: var(--pointer-events);
  cursor: pointer;

  &:hover,
  &[data-selected="true"] {
    background: var(--shade3-15);
  }

  &[data-selected="true"]:not([data-skeleton="true"]) {
    box-shadow: inset 2px 0 var(--turq);
  }

  &[data-skeleton="true"] {
    pointer-events: none;

    .vs,
    .item,
    .secondary,
    .winrate {
      opacity: 0;
      visbility: hidden;
    }
  }

  &.loading-item {
    pointer-events: none;

    .skeleton {
      height: var(--sp-8);
      width: 100%;
      background: var(--shade6-25);
      border-radius: var(--br);
    }
  }

  .pro-img {
    position: relative;
    width: var(--sp-9);
    height: auto;
    aspect-ratio: 1;
    background: var(--shade10);
    border-radius: 50%;
    overflow: hidden;
  }
  .headshot {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[data-fame="star"],
  &[data-fame="famous"] {
    .pro-name {
      color: var(--yellow);
    }
  }
  &[data-fame="star"] {
    .pro-img {
      box-shadow: 0 0 0 2px var(--yellow);
      background: hsla(var(--yellow-hsl) / 0.15);
    }
  }
  &[data-fame="famous"] {
    .pro-img {
      box-shadow: 0 0 0 2px var(--shade0-15);
    }
  }
  &[data-is-old-patch="true"] {
    .subtitle {
      /* color: var(--red); */
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: var(--sp-2);

    span,
    svg {
      width: var(--sp-3);
      height: var(--sp-3);
      color: var(--title-color);
    }
  }
  .title {
    color: var(--title-color);

    span {
      display: inline-block;
      color: var(--shade2);
      margin: 0 var(--sp-0_5);
    }
  }
  .subtitle {
    display: block;
    color: var(--shade1);
  }
  .build {
    filter: var(--blur);
  }
  .runes {
    position: relative;

    .secondary {
      position: absolute;
      bottom: -2px;
      right: -2px;
      padding: 2px;
      background: var(--shade8);
      border-radius: 50%;
    }
  }
  .keystone {
    margin-right: -0.25rem;
  }

  img.item {
    position: relative;
    background: var(--shade10);
    border-radius: var(--br-sm);
  }

  .winrate {
    position: relative;
    padding: var(--sp-0_5) var(--sp-1_5);
    color: var(--shade1);
    color: var(--winrate-color);

    > * {
      position: relative;
      display: block;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--br-sm);
      background: var(--winrate-color);
      opacity: 0.15;
    }
  }
`;

export const cssRightColumn = () => css`
  --gap: var(--sp-1);
  --row-gap: var(--sp-3);
  --size: var(--sp-9);
  --radii: var(--br-sm);
  --background: var(--shade8);

  display: flex;
  flex-direction: column;
  gap: var(--sp-4);

  [data-flow="LIVE"] & {
    gap: var(--sp-2);
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1_5);
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);

    .list-item {
      display: flex;
      align-items: center;
      gap: var(--gap);
    }

    &[data-ordered="true"] {
      .list-item:not(:last-child) {
        .chevron {
          display: block;
        }
      }
    }
    .chevron {
      display: none;
      fill: var(--shade2);
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  .item-image,
  img {
    display: block;
    width: var(--sp-7);
    aspect-ratio: 1;
    border-radius: var(--radii);
  }

  .groups {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
  }
  .group-title {
    color: var(--shade1);
  }

  .summoner-spells {
    [data-flow="LIVE"] & {
      display: none;
    }
  }
`;

// ~Right column rendered selected/imported build~
export const cssSelectedBuild = () => css`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--sp-2);
  padding: var(--sp-6);

  .title {
    display: none;
  }
  .build-name {
    & > span {
      color: var(--shade2);
    }
  }
  .import-status {
    color: var(--turq);
  }
  .build-patch {
    position: absolute;
    top: var(--sp-2);
    left: var(--sp-3);
    color: var(--shade2-75);

    [data-flow="LIVE"] & {
      left: unset;
      right: var(--sp-3);
    }
  }
  .build-body {
    display: flex;
    gap: var(--sp-8);

    [data-flow="LIVE"] & {
      flex-direction: column;
      gap: var(--sp-4);
    }
  }
  .build-left {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: var(--sp-4);
  }
  .runes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-1);

    [data-flow="LIVE"] & {
      display: none;
    }
  }
  .skill-order {
    display: grid;
    gap: var(--sp-1);
  }
  .build-right {
    flex: 1;
  }
  .build-order {
    height: 100%;
  }

  .new-rune-options-notif {
    --bg: hsla(var(--green-hsl) / 0.15);
    display: flex;
    align-items: center;
    gap: var(--sp-2);
    padding: var(--sp-1);
    color: var(--green);
    background: var(--bg);
    box-shadow: inset 0 0 0 1px var(--bg);
    border-radius: var(--br);

    .new-tag {
      padding: var(--sp-0_5) var(--sp-1_5);
      background: var(--bg);
      border-radius: var(--br-sm);
    }

    [data-flow="LIVE"] & {
      display: none;
    }
  }
`;

export const cssTag = () => css`
  --radius: var(--br);
  --gap: var(--sp-1);
  --color: currentColor;
  --icon-size: var(--sp-4);

  position: relative;
  display: inline-flex;
  align-items: center;
  gap: var(--gap);
  font-family: "Inter", sans-serif;
  white-space: nowrap;
  color: var(--color);
  border-radius: var(--radius);

  > * {
    position: relative;
  }

  svg,
  img {
    height: var(--icon-size);
    width: var(--icon-size);
    display: block;
  }

  svg {
    fill: currentColor;
  }

  .icon-left {
    margin-left: calc(var(--sp-1) * -1.5);
  }
  .icon-right {
    margin-right: calc(var(--sp-1) * -1.5);
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--color);
    opacity: 0.15;
    border-radius: var(--radius);
  }

  &.xs {
    --radius: var(--sp-1);
    --gap: var(--sp-0_5);
    --icon-size: var(--sp-3);

    padding: 0 var(--sp-1);
  }

  &.sm {
    --radius: var(--sp-1);
    --icon-size: var(--sp-3_5);

    padding: calc(var(--sp-0_5) / 2) var(--sp-1_5);
  }

  &.med {
    --icon-size: var(--sp-4);

    padding: var(--sp-0_5) var(--sp-2);
  }

  &.lg,
  &.large {
    --icon-size: var(--sp-4_5);
    padding: var(--sp-1_5) var(--sp-2);
  }
`;
