import { IS_APP, IS_TESTING } from "@/__main__/constants.mjs";
import { container } from "@/game-lol/components/in-game-view.mjs";
import { handleLCUEvent } from "@/game-lol/utils/lol-client-api.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import { isARAMQueue, isArenaQueue } from "@/game-lol/utils/util.mjs";

export default container;

export function meta(_params, searchParams) {
  // We're abusing the fact that this function runs upon entering this route.
  if (!IS_APP && !IS_TESTING) {
    const queue = searchParams.get("queue");
    const queueDetails = QueueSymbol(queue);

    // This is solely for the purpose of providing "content" on the page when
    // ad vendors wanna validate that the page contains content.
    if (isARAMQueue(queueDetails)) {
      (async () => {
        // Gameflow
        const { default: fixtureGameflow } = await import(
          "@/game-lol/fixtures/lol-inprogress-aram-fixture.json"
        );
        handleLCUEvent(fixtureGameflow);

        // Champ select
        const { default: fixtureChampSelect } = await import(
          "@/game-lol/fixtures/lol-champion-select-custom-fixture-aram.json"
        );
        handleLCUEvent(fixtureChampSelect);
      })();
    } else if (isArenaQueue(queueDetails)) {
      (async () => {
        // Gameflow
        const { default: fixtureGameflow } = await import(
          "@/game-lol/fixtures/lol-inprogress-arena-fixture.json"
        );
        handleLCUEvent(fixtureGameflow);

        // Champ select
        const { default: fixtureChampSelect } = await import(
          "@/game-lol/fixtures/lol-champion-select-custom-fixture-arena.json"
        );
        handleLCUEvent(fixtureChampSelect);
      })();
    } else {
      (async () => {
        // Gameflow
        const { default: fixtureGameflow } = await import(
          "@/game-lol/fixtures/lol-inprogress-fixture.json"
        );
        handleLCUEvent({
          data: fixtureGameflow,
          eventType: "Update",
          uri: "/lol-champ-select/v1/session",
        });

        // Champ select
        const { default: fixtureChampSelect } = await import(
          "@/game-lol/fixtures/lol-champion-select-custom-fixture.json"
        );
        handleLCUEvent(fixtureChampSelect);
      })();
    }
  }

  return {
    title: ["lol:liveData.inGame", "In Game"],
    description: [
      "lol:builds.autoImport.content",
      "Enable auto-importing of Items, Runes, and Summoners when you enter champion select. Set it and forget it.",
    ],
  };
}
