import { css } from "goober";

export const cssSettingsDialog = () => css`
  position: relative;
  background: var(--shade7);
  color: inherit;
  border: none;
  padding: var(--sp-10);
  border-radius: var(--br-lg);

  &.floating-controls {
    padding-bottom: 0;
  }

  &::backdrop {
    background: hsla(222deg 33% 4% / 0.75);
    cursor: pointer;
  }

  .subtitle {
    color: var(--shade1);
    padding-bottom: var(--sp-4);
  }

  .settings-list {
    display: flex;
    flex-direction: column;

    > * {
      border-top: 1px solid var(--shade6);

      &:last-child {
        border-bottom: 1px solid var(--shade6);
      }
    }
  }

  .setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sp-8);
    padding-block: var(--sp-4);

    &.child {
      padding-top: 0;
    }

    .info {
      display: flex;
      align-items: center;
      gap: var(--sp-4);
    }

    label {
      display: block;
    }
  }

  .description {
    color: var(--shade2);
    max-width: 45ch;
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--sp-6);

    &.floating-controls {
      position: sticky;
      display: inline-block;
      margin-block: var(--sp-4);
      padding: var(--sp-3);
      bottom: var(--sp-4);
      left: 50%;
      background: var(--shade9);
      border-radius: var(--br);
      transform: translateX(-50%);
      box-shadow: 0 11px 21px var(--shade8);
    }
  }

  .button-group {
    button {
      background: var(--shade3-25);
      color: var(--shade1);

      &[data-active="true"] {
        color: var(--blue) !important;
        background: hsla(var(--blue-hsl) / 0.15) !important;

        &[data-is-red="true"] {
          color: var(--red) !important;
          background: hsla(var(--red-hsl) / 0.15) !important;
        }
      }
    }
  }
`;

export const cssSettingsModal = () => css`
  width: 100%;
  max-width: 40rem;

  .setting {
    .info {
      align-items: flex-start;

      > img {
        box-shadow:
          0 0 0 1px var(--shade10),
          0 0 0 2px var(--shade4);
      }
    }
    .rune-img {
      border-radius: 50%;
    }
  }

  .summoner-selections {
    display: flex;
    align-items: center;
    gap: var(--sp-2);

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--sp-1);
      padding: 0;
      background: transparent;
      cursor: pointer;

      &[data-active="true"] {
        img {
          box-shadow:
            0 0 0 1px var(--shade10),
            0 0 0 2px var(--blue);
        }
      }
      &:not([data-active="true"]) {
        color: var(--shade2);

        img {
          opacity: 0.4;
          box-shadow:
            0 0 0 1px var(--shade10),
            0 0 0 2px var(--shade1);
          filter: saturate(0.2);
        }

        &:hover {
          color: var(--shade0);

          img {
            opacity: 1;
            filter: saturate(1);
          }
        }
      }
    }
  }
`;

export const cssOverlayModal = () => css`
  width: 100%;
  max-width: 45rem;

  .setting {
    .info {
      > img {
        border: 2px solid var(--shade9);
        box-shadow: 0 0 0 2px var(--shade5);
        border-radius: var(--br);
      }
    }
  }

  .description {
    max-width: 32ch;
  }
  .disclaimer {
    display: inline-block;
    color: var(--red);
    background: var(--red-15);
    padding: var(--sp-1) var(--sp-2);
    border-radius: var(--br-sm);
  }
`;

export function cssBuildSettings() {
  return [cssSettingsDialog(), cssSettingsModal()].join(" ");
}

export function cssOverlaySettings() {
  return [cssSettingsDialog(), cssOverlayModal()].join(" ");
}
