import { cssSelectOptions } from "clutch/src/Select/Select.jsx";
import { t } from "i18next";
import s2, { computed } from "s2-engine";

import {
  IS_APP,
  IS_NODE_BROWSERLESS,
  IS_TESTING,
} from "@/__main__/constants.mjs";
import { writeSettings } from "@/app/actions.mjs";
import { kdaColorStyle } from "@/app/util.mjs";
import template from "@/game-lol/components/in-game-template.mjs";
import {
  BOOTS,
  BUILD_HASH_DEFAULT,
  BUILD_SORT,
  MAX_BUILDS_SHOWN,
} from "@/game-lol/constants/builds-constants.mjs";
import LoLColors from "@/game-lol/constants/colors.mjs";
import {
  ARAM_TRADE_STATES,
  FLASH_PLACEMENT_LEFT,
  FLASH_PLACEMENT_RIGHT,
  INGAME_PHASES,
  INGAME_TABS,
  MAX_TAGS,
  PREMADE_LABELS,
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  ROLE_INDEX,
  ROLE_PAIRS,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import LoLDefinition from "@/game-lol/definition.mjs";
import derivedState from "@/game-lol/in-game-derived-state.mjs";
import inGameState, { inGameData } from "@/game-lol/in-game-state.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { placementColor } from "@/game-lol/utils/arena-queue-utils.mjs";
import championProficiency from "@/game-lol/utils/get-champion-proficiency.mjs";
import getRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import {
  BUILD_DELIMITER,
  buildDetails,
} from "@/game-lol/utils/in-game-builds.mjs";
import {
  showBuildSettingsModal,
  showOverlaySettingsModal,
} from "@/game-lol/utils/in-game-settings.mjs";
import {
  didBuildsError,
  formatBuildItems,
  formatBuildRunes,
  formatBuildSummonerSpells,
  formatSkillOrder,
  isBanPhase,
  isDeclarePhase,
  isInProgress,
  timeAgo,
} from "@/game-lol/utils/in-game-util.mjs";
import {
  createPlaystyleTags,
  renderedTags,
} from "@/game-lol/utils/in-game-vanity-tags.mjs";
import getItemTooltip from "@/game-lol/utils/item-tooltip.mjs";
import {
  acceptAramTrade,
  aramBenchSwap,
  aramReroll,
  aramRerollAndKeep,
  declineAramTrade,
  pickOrBanChampion,
  requestAramTrade,
  sendChampionSelectMessage,
} from "@/game-lol/utils/lol-client-api.mjs";
import { isOldPatch } from "@/game-lol/utils/patch-utils.mjs";
import rolesStats from "@/game-lol/utils/roles-stats.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import {
  aggregatePlayerChampions,
  calculateTeamDamages,
  damageBreakdownUI,
  determineFlashPlacement,
  getCurrentPatchForStaticData,
  getDerivedId,
  getDerivedRiotID,
  getStaticChampionById,
  getStaticData,
  getWinRateColor,
  isARAMQueue,
  isArenaQueue,
  isNexusBlitzQueue,
  isOneForAllQueue,
  isQueueWithoutRoles,
  isSwarmQueue,
  isURFQueue,
  playerChampionStats,
  proFame,
} from "@/game-lol/utils/util.mjs";
import * as BlitzLogo from "@/inline-assets/blitz-logo-bolt.svg";
import * as Close from "@/inline-assets/close.svg";
import * as Dice from "@/inline-assets/dice.svg";
import * as ExclamationMark from "@/inline-assets/exclamation-mark.svg";
import * as LoadingSpinner from "@/inline-assets/loading-spinner.svg";
import * as MastersPlus from "@/inline-assets/lol-rank-master-plus.svg";
import * as RoleAll from "@/inline-assets/lol-role-all.svg";
import * as RoleBot from "@/inline-assets/lol-role-bot.svg";
import * as RoleJng from "@/inline-assets/lol-role-jungle.svg";
import * as RoleMid from "@/inline-assets/lol-role-mid.svg";
import * as RoleSup from "@/inline-assets/lol-role-support.svg";
import * as RoleTop from "@/inline-assets/lol-role-top.svg";
import * as Overlay from "@/inline-assets/overlays.svg";
import * as Swap from "@/inline-assets/swap.svg";
import * as Swords from "@/inline-assets/swords.svg";
import * as Warning from "@/inline-assets/Warning.svg";
import getTierColor from "@/shared/get-tier-color.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import clone from "@/util/clone.mjs";
import globals from "@/util/global-whitelist.mjs";
import { formatGameTime } from "@/util/helpers.mjs";
import { getLocale, toPercent } from "@/util/i18n-helper.mjs";
import SymbolMap from "@/util/symbol-map.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";

const { overlays: LOL_OVERLAYS } = LoLDefinition;

function clickedInside(event, rect) {
  if (!event || !rect) return false;
  return (
    rect.top <= event.clientY &&
    event.clientY <= rect.top + rect.height &&
    rect.left <= event.clientX &&
    event.clientX <= rect.left + rect.width
  );
}

const roleIcons = new SymbolMap({
  [ROLE_SYMBOLS.all]: RoleAll.svg,
  [ROLE_SYMBOLS.top]: RoleTop.svg,
  [ROLE_SYMBOLS.jungle]: RoleJng.svg,
  [ROLE_SYMBOLS.mid]: RoleMid.svg,
  [ROLE_SYMBOLS.adc]: RoleBot.svg,
  [ROLE_SYMBOLS.adc]: RoleBot.svg,
  [ROLE_SYMBOLS.support]: RoleSup.svg,
});

// Disabling this lets us remove the root node from the DOM
// and continue updating as it should. Otherwise the default behavior
// is to call `unmount` when root nodes are removed from DOM.
s2.shouldUnmountRoot = false;

// [lolKey, fallback, options (optional)]
const lolTranslations = [
  ["rankedStats", "Ranked Stats"],
  ["championProficiency", "Champion proficiency"],
  ["teams.your", "Your Team"],
  ["teams.enemy", "Enemy Team"],
  ["teamDamageBreakdown", "Team damage breakdown"],
];

const parseTranslation = ([rawKey, fallback, opts = {}]) => {
  return t(rawKey, fallback, {
    ns: ["lol"],
    ...opts,
  });
};

const translations = lolTranslations.reduce((p, c) => {
  const sanitizedKey = c[0].replace(/\./g, "_");
  const fnKey = `t_${sanitizedKey}`;
  const value = parseTranslation(c);
  p[fnKey] = () => value;
  return p;
}, {});

const computedDamages = ({
  roster = [],
  noTitle = false,
  teammates,
  isArena,
  canCopy = false,
  isSmall = false,
}) => {
  if (!inGameState.currentState) return null;
  const { options } = inGameState;
  const { championStats } = inGameData;
  const { apDamageShare, adDamageShare, trueDamageShare } =
    calculateTeamDamages(roster, championStats);

  const {
    apColor,
    adColor,
    trueColor,
    adPercentText,
    apPercentText,
    truePercentText,
  } = damageBreakdownUI({ apDamageShare, adDamageShare, trueDamageShare });

  const hasData = !!(apDamageShare || adDamageShare || trueDamageShare);
  const isInGame = inGameState.currentState?.phase?.type === INGAME_PHASES[5];
  const canClick = canCopy && hasData && !isInGame;

  let subtitleText = isInGame
    ? ""
    : t("common:clickToPostInChat", "Click to post in chat");
  const isCopied = teammates
    ? options.damageReportTeamCopied
    : options.damageReportEnemyCopied;

  if (isCopied) {
    subtitleText = t("common:sent", "Sent!");
  }

  return {
    title: !noTitle && {
      text: isArena
        ? t("lol:lobbyDamageBreakdown", "Lobby Damage Breakdown")
        : teammates
          ? t("lol:allyDamageBreakdown", "Team damage breakdown")
          : t("lol:enemyDamageBreakdown", "Enemy damage breakdown"),
    },
    subtitle: !noTitle &&
      canClick && {
        text: subtitleText,
        style: isCopied ? "color: var(--green);" : "",
      },
    adPercentText,
    apPercentText,
    truePercentText,
    apStyles: `flex: ${apDamageShare || 1}; --dmg-color: ${apColor}`,
    adStyles: `flex: ${adDamageShare || 0}; --dmg-color: ${adColor}`,
    trueStyles: `flex: ${trueDamageShare || 0}; --dmg-color: ${trueColor}`,
    canClick, // This is just used for pointer styling
    isSmall,
    tooltip:
      !isInGame && canClick
        ? t(
            "lol:clickToShareDamageBreakdown",
            "Click to share this damage breakdown with your team in the League Client chat!",
          )
        : null,
    onClick: () => {
      if (!canCopy || isInGame) return;
      const format = {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };
      const params = {
        apDamageShare: (apDamageShare / 100).toLocaleString(
          getLocale(),
          format,
        ),
        adDamageShare: (adDamageShare / 100).toLocaleString(
          getLocale(),
          format,
        ),
        trueDamageShare: (trueDamageShare / 100).toLocaleString(
          getLocale(),
          format,
        ),
      };

      // The line breaks here are intentional to make the message more readable in the league client
      const text = teammates
        ? t(
            "lol:teamDamageBreakdownEmoji",
            `Our Team Damage:
            🟣{{apDamageShare}} AP 🔴{{adDamageShare}} AD ⚪{{trueDamageShare}} True`,
            params,
          )
        : t(
            "lol:enemyDamageBreakdownEmoji",
            `Enemy Team Damage:
            🟣{{apDamageShare}} AP 🔴{{adDamageShare}} AD ⚪{{trueDamageShare}} True`,
            params,
          );

      if (hasData) {
        // globals.navigator.clipboard.writeText(text);
        sendChampionSelectMessage(
          inGameState.currentState.multiUserChatId,
          text,
        );

        if (teammates) {
          inGameState.options.damageReportTeamCopied = true;
          sendInteractionEvent("damage-report-click-teammates");

          setTimeout(() => {
            inGameState.options.damageReportTeamCopied = false;
          }, 2000);
        } else {
          inGameState.options.damageReportEnemyCopied = true;
          sendInteractionEvent("damage-report-click-enemies");

          setTimeout(() => {
            inGameState.options.damageReportEnemyCopied = false;
          }, 2000);
        }
      }
    },
  };
};

function computedSummoner({ cellId, isUserTeammate, index }) {
  if (!inGameState.currentState) return null;

  const patch = getCurrentPatchForStaticData();
  const isARAM =
    isARAMQueue(inGameState.queueId, inGameState.mapId) ||
    isURFQueue(inGameState.queueId);
  const isArena = isArenaQueue(inGameState.queueId);
  const queueWithoutRoles = isQueueWithoutRoles(
    inGameState.queueId,
    inGameState.mapId,
  );

  const {
    region,
    playersState,
    liveClientState,
    currentState: { summonersByCellId },
  } = inGameState;

  const {
    playStylesBySummonerId,

    summonerIdByChampionId = {},
    summonerIdByGameName = {},
    riotGameNameBySummonerId = {},
    riotTagLineBySummonerId = {},

    teamOne,
    teamTwo,
  } = playersState;

  const gameflowTeam = isUserTeammate ? teamOne : teamTwo;
  const liveClientTeam = isUserTeammate
    ? liveClientState?.teammates
    : liveClientState?.enemies;

  const cellInfo = summonersByCellId?.[cellId];
  const gameflowInfo = gameflowTeam?.[index];
  const liveClientInfo = liveClientTeam?.[index];

  if (!cellInfo && !gameflowInfo) return null;

  // These values are used to cross-reference and map data for players
  let gameName = "";
  let tagLine = "";
  let summonerId = 0;
  let championId = 0;
  let roleSymbol = null;
  let spell1Id = 0;
  let spell2Id = 0;

  if (liveClientInfo) {
    championId = liveClientInfo.championId || 0;
    gameName = liveClientInfo.riotIdGameName || "";
    tagLine = liveClientInfo.riotIdTagLine || "";
    summonerId =
      summonerIdByGameName[gameName] || summonerIdByChampionId[championId] || 0;
    roleSymbol = liveClientInfo.role ? RoleSymbol(liveClientInfo.role) : null;
    spell1Id = liveClientState.summonerSpells[gameName]?.[0]?.key;
    spell2Id = liveClientState.summonerSpells[gameName]?.[1]?.key;
  } else if (gameflowInfo) {
    summonerId = gameflowInfo.summonerId || 0;
    championId = 0;
    gameName = riotGameNameBySummonerId[summonerId] || "";
    tagLine = riotTagLineBySummonerId[summonerId] || "";
    roleSymbol =
      RoleSymbol(gameflowInfo.selectedPosition) ||
      RoleSymbol(gameflowInfo.role);
  } else if (cellInfo) {
    summonerId = cellInfo.summonerId || 0;
    championId = cellInfo.championId || cellInfo.championPickIntent || 0;
    gameName = riotGameNameBySummonerId[summonerId] || "";
    tagLine = riotTagLineBySummonerId[summonerId] || "";
    roleSymbol = cellInfo.roleSymbol;
  }
  // ex: na1:Rio-Rio (region:gameName-tagLine)
  const derivedId = getDerivedId(inGameState.region, null, gameName, tagLine);

  const playStyles = playStylesBySummonerId[summonerId] || {};

  return computed({
    style() {
      const order = roleSymbol ? ROLE_INDEX[roleSymbol] : championId;
      return `order: ${order}`;
    },
    id() {
      return gameName && tagLine
        ? `${gameName}#${tagLine}`
        : `player-${cellId}`;
    },
    isInProgress() {
      const { isInProgress } = cellInfo;
      return !!isInProgress;
    },
    name() {
      let displayName = "...";

      if (gameName) {
        displayName = gameName;
      } else if (isArena) {
        displayName = "...";
      } else {
        displayName = t("lol:summoner", "Summoner");
      }

      return displayName;
    },
    championName() {
      const champion = getStaticChampionById(championId, patch);
      return champion?.name || "...";
    },
    championImgUrl() {
      if (!championId) return null;
      return Static.getChampionImage(championId);
    },
    rankBorder() {
      const { accountsBySummonerId } = playersState;
      const playerRanks = clone(accountsBySummonerId[summonerId]?.latestRanks);
      const currentQueueStats = playerRanks?.find(
        (q) => QueueSymbol(q.queue) === inGameState.queueId,
      );

      const soloqueueStats = playerRanks?.find(
        (q) => QueueSymbol(q.queue) === QUEUE_SYMBOLS.rankedSoloDuo,
      );

      const stats = currentQueueStats || soloqueueStats || {};
      const { wins = 0, losses = 0 } = stats;
      const games = wins + losses;
      const tierSymbolKey = RANK_SYMBOL_TO_STR[stats?.tier]?.key;

      return {
        icon: tierSymbolKey && getRankIcon(tierSymbolKey, true),
        romanNumeral: stats?.rank,
        wl: games
          ? t(
              "lol:matchHistory.winsAndLossesWithHypen",
              "{{wins}}W-{{losses}}L",
              {
                wins: wins,
                losses: losses,
              },
            )
          : null,
        class: `rank-border tier-${tierSymbolKey?.toLowerCase()}`,
      };
    },
    championSplashBackgroundImg() {
      if (!championId) return null;
      return Static.getChampionCenterImage(championId);
    },
    profileLink() {
      return gameName && tagLine && region
        ? `/lol/profile/${region}/${getDerivedRiotID(gameName, tagLine)}`
        : "#";
    },
    championLink() {
      const champion = getStaticChampionById(championId, patch);

      return champion
        ? `/${lolRefs.lolChampionPrefix}/${champion.key}/build`
        : "#";
    },
    usingBlitz() {
      if (!inGameState.currentState) return null;

      const {
        rtChannels,
        currentState: { multiUserChatId },
      } = inGameState;
      const channel = rtChannels[multiUserChatId?.replace(/-team\d+$/, "")] ?? {
        ids: [],
      };
      const names = channel.ids.map((p) => p.name ?? p.lol);

      if (!names.includes(derivedId)) return null;

      return {
        logoSvg: BlitzLogo.svg,
        label: t("common:blitzUser", "{{name}} is using Blitz.", {
          name: gameName && tagLine ? `${gameName} #${tagLine}` : gameName,
        }),
      };
    },

    party() {
      if (!inGameState.currentState) return null;
      const { premades } = derivedState;
      const premade = premades?.[derivedId];
      const size = premade?.partySize || 1;
      const label =
        size <= 3 && PREMADE_LABELS[size]
          ? { text: t(...PREMADE_LABELS[size]) }
          : null;

      return {
        size,
        dots: new Array(size).fill(0),
        party: premade?.partyId || 1,
        label,
      };
    },
    mostPlayed() {
      if (!inGameState.currentState) return null;
      const { championStatsBySummonerId } = playersState;

      if (!championStatsBySummonerId[summonerId]) return null;

      const stats = aggregatePlayerChampions(
        championStatsBySummonerId[summonerId],
      );
      const list = Object.values(stats)
        .filter((champion) => champion.championId)
        .sort((a, b) => b.games - a.games || b.kills - a.kills);

      if (!list.length) return null;

      return {
        tooltip: t(
          "lol:playerStats.mostPlayedChampions",
          "Most Played Champions",
        ),
        list: list.slice(0, 3).map((champion) => {
          return {
            src: Static.getChampionImage(champion.championId),
          };
        }),
      };
    },
    tags() {
      if (isARAM) return null;
      if (!playStyles) return [];
      const tagsData = createPlaystyleTags(
        playStyles,
        roleSymbol,
        championId,
        !queueWithoutRoles,
      );

      return renderedTags(tagsData)
        .sort((a, b) => a.order - b.order)
        .slice(0, MAX_TAGS);
    },
    showTags() {
      return () => {};
    },
    champProficiency() {
      const champion = getStaticChampionById(championId, patch);

      if (!playStyles || !championId) return null;

      const proficiency = championProficiency(
        championId,
        champion?.name,
        playStyles,
      );

      return proficiency;
    },
    spell1() {
      if (spell1Id > 0 && spell1Id < 10000) {
        // dumb bots summerspell ids
        const spellUrl = Static.getSummonerSpellImageById(spell1Id);
        return spellUrl;
      }

      return null;
    },
    spell2() {
      if (spell2Id > 0 && spell2Id < 10000) {
        // dumb bots summerspell ids
        const spellUrl = Static.getSummonerSpellImageById(spell2Id);
        return spellUrl;
      }

      return null;
    },
    hasSummonerSpells() {
      if (!inGameState.currentState) return false;

      return !![this.spell1, this.spell2].filter(Boolean).length;
    },
    runes() {
      const runes = {
        keystone: null,
        secondary: null,
      };

      if (!inGameState.currentState) return runes;

      if (liveClientState?.runes?.[gameName]) {
        runes.keystone = Static.getRuneImage(
          liveClientState.runes[gameName][0],
        );
        runes.secondary = Static.getRuneImage(
          liveClientState.runes[gameName][1],
        );
      }

      return runes;
    },
    hasRunes() {
      if (!inGameState.currentState) return false;

      return !![this.runes.keystone, this.runes.secondary].filter(Boolean)
        .length;
    },
    hasAssignedPosition() {
      return isARAM ? false : roleSymbol && roleSymbol !== ROLE_SYMBOLS.all;
    },
    roleSvg() {
      const { trade } = cellInfo;

      if (isARAM) {
        // ARAM trading
        if (trade?.state !== ARAM_TRADE_STATES.available) return null;

        return computed({
          roleSvg: Swap.svg,
          tooltip: t("lol:trade.tradeChampions", "Trade Champions"),
          onClick: () => requestAramTrade(trade?.championId),
          style: "cursor: pointer;",
        });
      }

      // Non-ARAM queues
      if (!roleSymbol || roleSymbol === ROLE_SYMBOLS.all) return null;

      const { roleSvg, icon, tooltip } = rolesStats(roleSymbol, playStyles);

      return {
        roleSvg,
        tooltip,
        proficiencySvg: icon,
      };
    },
    stats() {
      const { championStatsBySummonerId } = playersState;

      if (!championStatsBySummonerId[summonerId]) return null;

      const stats = aggregatePlayerChampions(
        championStatsBySummonerId[summonerId],
      );
      const championStats = playerChampionStats(stats, championId);

      return {
        noStats: stats && !championId,
        style: `
          --wr-color: ${
            championStats.games &&
            getWinRateColor((championStats.wins / championStats.games) * 100)
          };
          --kda-color: ${
            championStats.games && kdaColorStyle(championStats.kda)
          };
        `,
        champImage: championId ? Static.getChampionImage(championId) : null,
        winrate: (
          championStats.wins / (championStats.games || 1)
        ).toLocaleString(getLocale(), {
          style: "percent",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }),
        gamesPlayed: t("common:stats.gamesCountPlayed", "{{count}} Played", {
          count: championStats.games.toLocaleString(getLocale()),
        }),
        kda: championStats.games
          ? t("lol:matchTile.kda", "{{kda}} KDA", {
              kda: championStats.kda.toLocaleString(getLocale(), {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
            })
          : "-",
        kdaString: t("lol:displayKDA", "{{kills}} / {{deaths}} / {{assists}}", {
          kills: (
            championStats.kills / (championStats.games || 1)
          ).toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          deaths: (
            championStats.deaths / (championStats.games || 1)
          ).toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          assists: (
            championStats.assists / (championStats.games || 1)
          ).toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        }),
      };
    },
    hideDamage() {
      return !championId;
    },
    damage() {
      return computedDamages({
        roster: [{ championId }],
        noTitle: true,
        canCopy: false,
        isSmall: true,
      });
    },
    tradeAccept() {
      if (!inGameState.currentState) return null;
      const {
        currentState: { localPlayerCellId, summonersByCellId, trades },
      } = inGameState;
      const { championStats = [] } = inGameData;

      const isLocalPlayer = localPlayerCellId === Number.parseInt(cellId);
      const incomingTrade = (trades || []).find(
        (trade) => trade?.state === ARAM_TRADE_STATES.received,
      );

      if (!isLocalPlayer || !incomingTrade) return null;

      const stats =
        championStats.find((champ) => champ.championId === incomingTrade.id) ||
        {};

      const { wins = 0, games = 1, tierListTier = {} } = stats;
      const winrate = wins / games;

      const championId = summonersByCellId?.[incomingTrade.cellId]?.championId;
      const champion = getStaticChampionById(championId, patch);

      return computed({
        title: t(
          "lol:trade.askedUnkown",
          "Someone is asking to trade with you.",
        ),
        championImgUrl: Static.getChampionImage(championId),
        championBgImgUrl: Static.getChampionCenterImage(championId),
        championName: champion?.name || t("common:unknownName", "Unknown Name"),
        tierColor: getTierColor(tierListTier.tierRank),
        tierIcon: tierListTier.tierRank
          ? `<img src="${getTierIcon(tierListTier.tierRank)}" width="36" height="36" />`
          : null,
        winrate: winrate ? toPercent(winrate) : "-",
        winrateColor: winrate ? getWinRateColor(winrate * 100) : null,
        acceptText: t("lol:trade.acceptTrade", "Accept Trade"),
        declineText: t("lol:trade.declineTrade", "Decline Trade"),
        accept: () => acceptAramTrade(incomingTrade.id),
        decline: () => declineAramTrade(incomingTrade.id),
      });
    },
  });
}

let phaseTimeDuration = 0;
let timeInGame = 0;
let localGameStartTime = 0;
let initialStart = 0;
let currentPhaseType = "";
let lastTimeout = 0;
let remainingTime = 0;

// Suggestions stuff
const max = 15;
const min = 3;

function instaBanPick(championId, ui, type) {
  if (!championId) return;
  const { options, currentState, queueId } = inGameState;
  const { localPlayerCellId, phase, actions } = currentState;
  const phaseType = type || phase?.type === INGAME_PHASES[1] ? "ban" : "pick";

  // find the action id...
  let pickAction;
  if (!actions) return;

  for (const group of actions) {
    for (const action of group) {
      const { type, actorCellId } = action;

      if (actorCellId === localPlayerCellId && type === phaseType) {
        pickAction = action;
        break;
      }
    }
  }
  if (!pickAction) return;
  options.suggestionsClickedId = championId;
  pickAction.championId = championId;
  pickAction.isInProgress = false;
  pickAction.completed = true;
  pickOrBanChampion({
    pickAction,
    type: phaseType,
    ui,
    queueId,
  });
}

function isLivePhase(phaseType) {
  if (!(IS_APP || IS_TESTING)) return true;
  return phaseType === "INGAME" || phaseType === "INPROGRESS";
}

const inGameView = computed({
  loading() {
    if (inGameState.currentState) return null;
    return { icon: LoadingSpinner.svg };
  },

  flow() {
    return isLivePhase(inGameState.currentState?.phase?.type)
      ? "LIVE"
      : "DRAFT";
  },

  currPhase() {
    return inGameState.currentState?.phase?.type;
  },

  queueId() {
    if (!inGameState.currentState) return null;
    return inGameState.queueId;
  },

  settings() {
    return computed({
      buildSettings() {
        if (!inGameState.currentState) return null;
        const {
          settings: {
            hasOnboardedBuilds,
            autoImportSpells,
            autoImportRunes,
            autoImportItems,
            defaultFlashPlacement,
            manualFlashPlacement,
          },
        } = inGameState;

        const flashPlacement = determineFlashPlacement({
          manualFlashPlacement,
          defaultFlashPlacement,
        });

        return computed({
          title: t("common:settings.import.title", "Build Import Settings"),
          subtitle: t(
            "common:settings.import.subtitle",
            "Determine what will and will not be automatically imported by Blitz.",
          ),
          summonerSpells: {
            img: Static.getSummonerSpellImageById(14),
            title: "Summoner Spells",
            description: t(
              "common:settings.import.spellsSubtitle",
              "Set summoner spells automatically when entering champion select.",
            ),
            isActive: autoImportSpells,
            isDisabled: false,
            toggle: () => {
              writeSettings(["lol", "autoImportSpells"], !autoImportSpells);
            },
            flashPosition: autoImportSpells
              ? {
                  img: Static.getSummonerSpellImageById(4),
                  title: t(
                    "common:settings.import.preferredFlash.title",
                    "Default Flash placement",
                  ),
                  description: t(
                    "common:settings.import.preferredFlashPos.subtitle",
                    "Set the preferred placement of Flash when summoner spells are imported.",
                  ),
                  leftText: t(
                    "common:settings.import.preferredFlashPos.left",
                    "[Left]",
                  ),
                  leftActive: flashPlacement === FLASH_PLACEMENT_LEFT,
                  setLeft: () => {
                    writeSettings(
                      ["lol", "manualFlashPlacement"],
                      FLASH_PLACEMENT_LEFT,
                    );
                  },
                  rightText: t(
                    "common:settings.import.preferredFlashPos.right",
                    "[Right]",
                  ),
                  rightActive: flashPlacement === FLASH_PLACEMENT_RIGHT,
                  setRight: () => {
                    writeSettings(
                      ["lol", "manualFlashPlacement"],
                      FLASH_PLACEMENT_RIGHT,
                    );
                  },
                }
              : null,
          },
          runes: {
            img: Static.getRuneImage(8126),
            title: "Runes",
            description: t(
              "common:settings.import.runesSubtitle",
              "Set runes automatically when entering champion select.",
            ),
            isActive: autoImportRunes,
            isDisabled: false,
            toggle: () => {
              writeSettings(["lol", "autoImportRunes"], !autoImportRunes);
            },
          },
          items: {
            img: Static.getItemImage(3068),
            title: "Items",
            description: t(
              "common:settings.import.buildsSubtitle",
              "Import matchup and pro item builds into the in-game item shop.",
            ),
            isActive: autoImportItems,
            isDisabled: false,
            toggle: () => {
              writeSettings(["lol", "autoImportItems"], !autoImportItems);
            },
          },
          save: {
            text: !hasOnboardedBuilds
              ? t("common:next", "Next")
              : t("common:saveAndClose", "Save and Close"),
            emphasis: "high",
            onClick: () => {
              if (!hasOnboardedBuilds) {
                showBuildSettingsModal(false);
                showOverlaySettingsModal(true);
              } else {
                writeSettings(["lol", "hasOnboardedBuilds"], true);
                showBuildSettingsModal(false);
              }
            },
          },
          close: {
            text: t("common:close", "Close"),
            emphasis: "low",
            onClick: () => {
              writeSettings(["lol", "hasOnboardedBuilds"], true);
              showBuildSettingsModal(false);
            },
          },
          onDialogClick: (e) => {
            // Handle click outside to close
            if (e.target.tagName !== "DIALOG") return;
            const rect = e.target.getBoundingClientRect();

            if (clickedInside(e, rect) === false) {
              writeSettings(["lol", "hasOnboardedBuilds"], true);
              e.target.close();
            }
          },
        });
      },
      overlaySettings() {
        if (!inGameState.currentState) return null;
        const {
          settings: {
            overlays: {
              isTrinketEnabled,
              isUltimateTimersEnabled,
              isSummonersRiftOverlayEnabled,
              isItemDifferenceOverlayEnabled,
              isSummonersRiftJungleOverlayEnabled,
              isLoadingScreenOverlayEnabled,
              isHealthRelicOverlayEnabled,
              isSummonersRiftSkillOverlayEnabled2023, // Has an official Riot version
              isJunglePathingOverlayEnabled2023, // Has an official Riot version
              isSummonerSpellTrackerOverlayEnabled,
            } = {},
          },
        } = inGameState;
        const aramOverlays = {
          healthTimer: {
            img: LOL_OVERLAYS.healthTimer.images.small,
            title: t(...LOL_OVERLAYS.healthTimer.title),
            description: t(...LOL_OVERLAYS.healthTimer.description),
            isActive: isHealthRelicOverlayEnabled,
            toggle: () => {
              const key = LOL_OVERLAYS.healthTimer.key[0];
              writeSettings(
                ["lol", "overlays", key],
                !isHealthRelicOverlayEnabled,
              );
            },
          },
        };

        const srOverlays = {
          itemDifference: LOL_OVERLAYS.itemDifference
            ? {
                img: LOL_OVERLAYS.itemDifference.images.small,
                title: t(...LOL_OVERLAYS.itemDifference.title),
                description: t(...LOL_OVERLAYS.itemDifference.description),
                isActive: isItemDifferenceOverlayEnabled,
                toggle: () => {
                  const key = LOL_OVERLAYS.itemDifference.key[0];
                  writeSettings(
                    ["lol", "overlays", key],
                    !isItemDifferenceOverlayEnabled,
                  );
                },
              }
            : null,
          benchmarking: LOL_OVERLAYS.benchmark
            ? {
                img: LOL_OVERLAYS.benchmark.images.small,
                title: t(...LOL_OVERLAYS.benchmark.title),
                description: t(...LOL_OVERLAYS.benchmark.description),
                isActive: isSummonersRiftOverlayEnabled,
                toggle: () => {
                  const key = LOL_OVERLAYS.benchmark.key[0];
                  writeSettings(
                    ["lol", "overlays", key],
                    !isSummonersRiftOverlayEnabled,
                  );
                },
              }
            : null,
          junglePath: LOL_OVERLAYS.junglePath
            ? {
                // Has an official Riot version
                img: LOL_OVERLAYS.junglePath.images.small,
                title: t(...LOL_OVERLAYS.junglePath.title),
                description: t(...LOL_OVERLAYS.junglePath.description),
                conflictDisclaimer: isJunglePathingOverlayEnabled2023
                  ? {
                      text: t(...LOL_OVERLAYS.junglePath.conflictDisclaimer),
                    }
                  : null,
                isActive: isJunglePathingOverlayEnabled2023,
                toggle: () => {
                  const key = LOL_OVERLAYS.junglePath.key[0];
                  writeSettings(
                    ["lol", "overlays", key],
                    !isJunglePathingOverlayEnabled2023,
                  );
                },
              }
            : null,
          minimapTimer: {
            img: LOL_OVERLAYS.minimapTimer.images.small,
            title: t(...LOL_OVERLAYS.minimapTimer.title),
            description: t(...LOL_OVERLAYS.minimapTimer.description),
            isActive: isSummonersRiftJungleOverlayEnabled,
            toggle: () => {
              const key = LOL_OVERLAYS.minimapTimer.key[0];
              writeSettings(
                ["lol", "overlays", key],
                !isSummonersRiftJungleOverlayEnabled,
              );
            },
          },
          trinketReminder: {
            img: LOL_OVERLAYS.trinketReminder.images.small,
            title: t(...LOL_OVERLAYS.trinketReminder.title),
            description: t(...LOL_OVERLAYS.trinketReminder.description),
            isActive: isTrinketEnabled,
            toggle: () => {
              const key = LOL_OVERLAYS.trinketReminder.key[0];
              writeSettings(["lol", "overlays", key], !isTrinketEnabled);
            },
          },
        };

        const generalOverlays = {
          skillRecommended: {
            // Has an official Riot version
            img: LOL_OVERLAYS.skillRecommended.images.small,
            title: t(...LOL_OVERLAYS.skillRecommended.title),
            description: t(...LOL_OVERLAYS.skillRecommended.description),
            conflictDisclaimer: isSummonersRiftSkillOverlayEnabled2023
              ? {
                  text: t(...LOL_OVERLAYS.skillRecommended.conflictDisclaimer),
                }
              : null,
            isActive: isSummonersRiftSkillOverlayEnabled2023,
            toggle: () => {
              const key = LOL_OVERLAYS.skillRecommended.key[0];
              writeSettings(
                ["lol", "overlays", key],
                !isSummonersRiftSkillOverlayEnabled2023,
              );
            },
          },
          summonerSpellTracker: {
            img: LOL_OVERLAYS.summonerSpellTracker.images.small,
            title: t(...LOL_OVERLAYS.summonerSpellTracker.title),
            description: t(...LOL_OVERLAYS.summonerSpellTracker.description),
            isActive: isSummonerSpellTrackerOverlayEnabled,
            toggle: () => {
              const key = LOL_OVERLAYS.summonerSpellTracker.key[0];
              writeSettings(
                ["lol", "overlays", key],
                !isSummonerSpellTrackerOverlayEnabled,
              );
            },
          },
          skillTracker: {
            img: LOL_OVERLAYS.skillTracker.images.small,
            title: t(...LOL_OVERLAYS.skillTracker.title),
            description: t(...LOL_OVERLAYS.skillTracker.description),
            isActive: isUltimateTimersEnabled,
            toggle: () => {
              const key = LOL_OVERLAYS.skillTracker.key[0];
              writeSettings(["lol", "overlays", key], !isUltimateTimersEnabled);
            },
          },
          loadingScreen: {
            img: LOL_OVERLAYS.loadingScreen.images.small,
            title: t(...LOL_OVERLAYS.loadingScreen.title),
            description: t(...LOL_OVERLAYS.loadingScreen.description),
            isActive: isLoadingScreenOverlayEnabled,
            toggle: () => {
              const key = LOL_OVERLAYS.loadingScreen.key[0];
              writeSettings(
                ["lol", "overlays", key],
                !isLoadingScreenOverlayEnabled,
              );
            },
          },
        };

        return computed({
          title: t("common:overlaySettingsTitle", "Overlay Settings"),
          subtitle: t(
            "common:overlaySettingsSubtitle",
            "What in-game overlays would you like to have displayed?",
          ),

          ...srOverlays,
          ...generalOverlays,
          ...aramOverlays,

          save: {
            text: t("common:saveAndClose", "Save and Close"),
            emphasis: "high",
            onClick: () => {
              writeSettings(["lol", "hasOnboardedBuilds"], true);
              showOverlaySettingsModal(false);
            },
          },
          close: {
            text: t("common:close", "Close"),
            emphasis: "low",
            onClick: () => {
              writeSettings(["lol", "hasOnboardedBuilds"], true);
              showOverlaySettingsModal(false);
            },
          },
          onDialogClick: (e) => {
            // Handle click outside to close
            if (e.target.tagName !== "DIALOG") return;
            const rect = e.target.getBoundingClientRect();

            if (clickedInside(e, rect) === false) {
              e.target.close();
            }
          },
        });
      },
    });
  },

  summoners() {
    const { currentState, queueId } = inGameState;
    if (!currentState) return null;
    const { summonersByCellId } = currentState;

    const myTeamCellIds = currentState.myTeamCellIds || [];
    const theirTeamCellIds = currentState.theirTeamCellIds || [];

    const columnsCount = isArenaQueue(queueId) ? 4 : myTeamCellIds.length;

    return computed({
      ...translations,
      teammates() {
        return {
          style: `--players-per-team: ${columnsCount}`,
          players: myTeamCellIds.map((cellId, index) =>
            computedSummoner({
              index,
              cellId,
              isUserTeammate: true,
            }),
          ),
        };
      },
      opponentsTitle() {
        return t("common:enemyTeam", "Enemy Team");
      },
      opponents() {
        if (!theirTeamCellIds.length) return null;
        return {
          style: `--players-per-team: ${columnsCount}`,
          players: theirTeamCellIds.map((cellId, index) =>
            computedSummoner({
              index,
              cellId,
              isUserTeammate: false,
            }),
          ),
        };
      },
      teammatesTitle() {
        return t("common:yourTeam", "Your Team");
      },
      teammatesDamage() {
        if (isSwarmQueue(inGameState.queueId)) return null;
        return computedDamages({
          teammates: true,
          isArena: isArenaQueue(inGameState.queueId),
          roster: myTeamCellIds.map((id) => summonersByCellId[id]),
          canCopy: true,
        });
      },
      videoAd() {
        // only show in live game
        if (!inGameState.currentState) return null;
        if (!isLivePhase(inGameState.currentState.phase?.type)) return null;
        const { videoAd } = lolRefs.inGameFeatures;
        if (!videoAd) return null;

        return { element: videoAd };
      },
      opponentsDamage() {
        const { liveClientState, queueId } = inGameState;
        if (isArenaQueue(queueId) || isSwarmQueue(queueId)) return null;
        const enemyChampions = theirTeamCellIds.reduce((acc, curr, index) => {
          const summoner = summonersByCellId[curr] || {};
          let championId = summoner.championId;

          if (!championId && liveClientState) {
            championId = liveClientState.enemies?.[index]?.championId;
          }

          acc = [...acc, { championId }];
          return acc;
        }, []);

        return computedDamages({
          teammates: false,
          roster: enemyChampions,
          canCopy: true,
        });
      },
    });
  },

  tabs() {
    if (!inGameState.currentState) return null;

    const isARAM =
      isARAMQueue(inGameState.queueId, inGameState.mapId) ||
      isURFQueue(inGameState.queueId);
    const isArena = isArenaQueue(inGameState.queueId);
    const isNexusBlitz = isNexusBlitzQueue(inGameState.queueId);
    const isOneForAll = isOneForAllQueue(inGameState.queueId);
    const isSwarm = isSwarmQueue(inGameState.queueId);

    if (isSwarm) return null;

    return computed({
      timer() {
        if (!inGameState.currentState) return null;

        const { phase = {}, inGameTimer } = inGameState.currentState;
        const { remaining, totalTime, type, startTime } = phase;

        if (
          (phaseTimeDuration === 0 && remaining !== 0) ||
          currentPhaseType !== type
        ) {
          phaseTimeDuration = remaining;
          currentPhaseType = type;
          initialStart = startTime;
        }

        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(() => {
          if (!inGameState.currentState) return;
          if (remaining > 0) {
            const secondsExpired = Date.now() - initialStart;
            remainingTime = phaseTimeDuration - secondsExpired;
            phase.remaining = remainingTime;
          }
          if (type === INGAME_PHASES[5] && inGameTimer) {
            resetTimers();
            runningGameTimer();
          }
        }, 100);

        function resetTimers() {
          if (remainingTime !== 0) {
            phaseTimeDuration = 0;
            remainingTime = 0;
            phase.remaining = phaseTimeDuration;
          }
        }

        function runningGameTimer() {
          const { gameId, gameStartTime } = inGameState.gameInfo;
          if (gameId && gameStartTime === 0) {
            localGameStartTime = Date.now() - inGameTimer * 1000;
            inGameState.gameInfo.gameStartTime = localGameStartTime;
          }
          timeInGame = Date.now() - localGameStartTime;
          phase.totalTime = timeInGame;
        }

        function setPhaseTimer() {
          // TODO: fix remaining timer of current phase
          if (phase.remaining > 0 && type !== INGAME_PHASES[5]) {
            return t(
              "lol:secondsRemaining",
              "{{seconds}} seconds remaining...",
              {
                seconds: Math.max(0, Math.floor(remainingTime / 1000)),
              },
            );
          }

          if (
            type === INGAME_PHASES[5] &&
            inGameState.gameInfo.gameStartTime !== 0
          ) {
            const timer = Math.floor(totalTime / 1000);
            const formattedTime = formatGameTime(timer);
            return t(
              "lol:onlineIndicator.IN_GAME.description",
              "{{timeElapsed}} Elapsed",
              { timeElapsed: formattedTime },
            );
          }

          return "\u200B"; // zero-width space to prevent layout shift from 0h
        }

        return setPhaseTimer();
      },

      tabslist() {
        const { options } = inGameState;
        const { localPlayerChampionId, selectedTab, suggestionsRoot } =
          derivedState;
        const phase = inGameState.currentState?.phase?.type;
        const buildsTab = isArena ? INGAME_TABS[3] : INGAME_TABS[1];

        const suggestions = {
          id: INGAME_TABS[0],
          icon: BlitzLogo.svg,
          label: t("lol:suggestions.suggestions", "Suggestions"),
          isActive: selectedTab === INGAME_TABS[0],
          disabled: !!isARAM,
          onclick: (e) => {
            e.preventDefault();
            options.selectedTab = INGAME_TABS[0];
            sendInteractionEvent("champ-select-tab", { tab: INGAME_TABS[0] });
          },
        };
        const builds = {
          id: buildsTab,
          icon: Swords.svg,
          label: t("lol:builds.builds", "Builds"),
          isActive: selectedTab === buildsTab,
          disabled: !localPlayerChampionId,
          onclick: (e) => {
            e.preventDefault();
            options.selectedTab = buildsTab;
            sendInteractionEvent("champ-select-tab", { tab: buildsTab });
          },
        };
        // const probuilds = {
        //   icon: ProBuildsLogo.svg,
        //   label: t("lol:builds.proBuilds", "Pro Builds"),
        //   isActive: selectedTab === INGAME_TABS[2],
        //   // disabled: !localPlayerChampionId,
        //   disabled: true, // TODO: implement this tab at some point (low prio)
        //   onclick: (e) => {
        //     e.preventDefault();
        //     options.selectedTab = INGAME_TABS[2];
        //   },
        // };

        return suggestionsRoot && !isInProgress(phase)
          ? [suggestions, builds]
          : [builds];
      },

      activeTab() {
        const { selectedTab } = derivedState;
        return selectedTab;
      },

      primaryRoleKey() {
        return ROLE_SYMBOL_TO_STR[derivedState.role]?.key;
      },

      settingsButtons() {
        return computed([
          {
            icon: Swords.svg,
            emphasis: "medium",
            onclick: () => {
              showBuildSettingsModal(true);
              sendInteractionEvent("champ-select-settings-build-import");
            },
            text: t("common:settings.import.title", "Build Import Settings"),
            tooltip: t("common:settings.import.title", "Build Import Settings"),
            place: "left",
          },
          {
            icon: Overlay.svg,
            emphasis: "medium",
            onclick: () => {
              showOverlaySettingsModal(true);
              sendInteractionEvent("champ-select-settings-overlay");
            },
            text: t("common:overlaySettingsTitle", "Overlay Settings"),
            tooltip: t("common:overlaySettingsTitle", "Overlay Settings"),
            place: "left",
          },
        ]);
      },

      localChampion() {
        if (!inGameState.currentState || !derivedState.localPlayerChampionId)
          return null;

        return {
          src: Static.getChampionImage(derivedState.localPlayerChampionId),
        };
      },

      roleOptions() {
        if (isARAM || isArena || isNexusBlitz || isOneForAll) return null;
        const { options } = inGameState;
        const role = derivedState.role;

        const roleList = Object.values(ROLE_SYMBOLS).filter(
          (symbol) => symbol !== ROLE_SYMBOLS.all,
        );

        const buttons = roleList.map((roleSymbol) => {
          const roleLabel = ROLE_SYMBOL_TO_STR[roleSymbol].label;
          const isSelected = role === ROLE_SYMBOL_TO_STR[roleSymbol].internal;

          return computed({
            isSelected,
            onClick() {
              options.selectedRole = ROLE_SYMBOL_TO_STR[roleSymbol].internal;
              sendInteractionEvent("champ-select-role-select");
            },
            text: t(...roleLabel),
            tooltip: t(...roleLabel),
            icon: roleIcons[roleSymbol],
          });
        });

        return computed({
          roles: buttons,
        });
      },

      opponentSelect() {
        if (isARAM || isArena || !inGameState.currentState) return null;
        const { options, currentState } = inGameState;
        const { summonersByCellId, theirTeamCellIds } = currentState;

        const selectedOpponent = options.selectedOpponent;
        const guessedOpponent = derivedState.enemyLaner;
        const opponent = selectedOpponent || guessedOpponent;

        const opponents = (theirTeamCellIds || [])
          .map((cellId) => summonersByCellId[cellId])
          .filter((p) => Boolean(p.championId));

        const patch = getCurrentPatchForStaticData();

        if (!opponents?.length) return null;

        return computed({
          disabled: !opponents.length,
          text: t("common:vs", "vs"),
          selectedOpponent: Static.getChampionImage(opponent),
          close: () => {
            options.selectingOpponent = false;
          },
          isOpen: options.selectingOpponent,
          toggleSelectOpen: () => {
            options.selectingOpponent = !options.selectingOpponent;
          },
          optionsClass: options.selectingOpponent
            ? `${cssSelectOptions()} options-container open-left visible`
            : `${cssSelectOptions()} options-container open-left`,
          opponentOptions: opponents.map((p) => {
            const { championId } = p;
            const champion = getStaticChampionById(championId, patch);
            const name = champion?.name;
            const isSelected = opponent === championId;

            return computed({
              onClick: () => {
                options.selectedOpponent = championId;
                options.selectingOpponent = false;
                sendInteractionEvent("champ-select-opponent-select");
              },
              champClass: isSelected ? "option selected-option" : "option",
              checkClass: isSelected
                ? "option--check selected"
                : "option--check",
              image: Static.getChampionImage(championId),
              text: name || "",
            });
          }),
        });
      },

      lowRoleWarning() {
        const { options } = inGameState;
        if (isARAM || isArena || !inGameState.currentState) return null;

        const roleWarning = derivedState.lowRoleWarning;

        if (!roleWarning) return null;

        return computed({
          icon: ExclamationMark.svg,
          text: t(
            "lol:roleWarning",
            "This role is not commonly played. Your build may not be accurate or missing.",
          ),
          suggested: roleWarning.suggested
            ? {
                icon: roleIcons[roleWarning.suggested],
                text: t(ROLE_SYMBOL_TO_STR[roleWarning.suggested].label),
                label: t("common:changeTo", "Change to"),
                onClick() {
                  options.selectedRole =
                    ROLE_SYMBOL_TO_STR[roleWarning.suggested].internal;
                  sendInteractionEvent("champ-select-role-low-warning");
                },
              }
            : null,
        });
      },

      builds() {
        const genericErrorIcon = {
          iconSvg: ExclamationMark.svg,
          label: t("common:somethingWentWrong", "Ooops, something went wrong."),
        };
        const noMatchupsError = {
          iconSvg: ExclamationMark.svg,
          label: t(
            "lol:builds.noMatchupBuilds",
            "Not enough data for reliable matchup builds",
          ),
        };

        return computed({
          buildOptions() {
            const { buildOptions } = derivedState;

            return {
              blockOptions: true,
              options: buildOptions,
            };
          },
          cta() {
            if (!inGameState.currentState) return null;
            const { content, enabled } = lolRefs.inGameFeatures;
            const { buildSort } = derivedState;

            if (!content || enabled || buildSort !== BUILD_SORT.pro)
              return null;

            return {
              title: t(content.ctaTitle),
              description: t(content.ctaDescription),
            };
          },
          playstyleBuilds() {
            const { playstyleBuildsError } = inGameState;
            const { buildSort } = derivedState;
            const { content, enabled } = lolRefs.inGameFeatures;
            const { playstyleBuilds = [] } = derivedState;

            let title = t("lol:builds.playstyleBuilds", "Playstyle Builds");
            let style = null;
            let icon = null;

            if (buildSort === BUILD_SORT.pro && content) {
              title = t(content.unlockSections[1].title);
              style = `--section-color: ${LoLColors.ranks.master.text};`;
              icon = { iconSvg: MastersPlus.svg };
            }

            if (buildSort === BUILD_SORT.pro && !enabled) {
              return {
                icon,
                title,
                style,
                buildsList: content.buildsList,
              };
            }

            if (playstyleBuildsError) {
              title = t(
                "lol:builds.playstyleBuildsError",
                "Playstyle Builds Request Error",
              );
              style = "--section-color: var(--primary)";
            }

            const list = playstyleBuilds?.length
              ? playstyleBuilds.filter(({ error }) => !error)
              : !playstyleBuildsError
                ? [BUILD_HASH_DEFAULT, BUILD_HASH_DEFAULT]
                : playstyleBuilds || [];

            const buildsList = list
              .map((hash) => {
                const { buildKey, index, build } = hash;
                const {
                  runePages = [],
                  wins,
                  games,
                  items_completed,
                  misc,
                } = build;
                const listKey = `${buildKey}${BUILD_DELIMITER}${index}`;
                const isLoadingSkeleton = hash.isSkeleton;

                const mainItems = items_completed
                  .slice(0, 3)
                  .filter((item) => !BOOTS[item]);

                const isSelected =
                  listKey === derivedState.selectedBuild?.listKey;

                const winrate = wins / (games || 1);

                const { keystoneId, subStyleId } = runePages[0] || {};

                return {
                  isLoadingSkeleton,
                  title: !isLoadingSkeleton
                    ? misc.playstyleTitle
                    : t("common:loading", "Loading..."),
                  subtitle: games
                    ? t("lol:countGame_plural", "{{count}} Games", {
                        count: games.toLocaleString(getLocale()),
                      })
                    : "---",
                  keystone:
                    keystoneId && !isLoadingSkeleton
                      ? Static.getRuneImage(keystoneId)
                      : null,
                  secondaryTree:
                    subStyleId && !isLoadingSkeleton
                      ? Static.getRuneImage(subStyleId)
                      : null,
                  mainItems:
                    mainItems.length && !isLoadingSkeleton
                      ? mainItems.map((itemId) => {
                          return {
                            src: Static.getItemImage(itemId),
                          };
                        })
                      : null,
                  winrate: winrate.toLocaleString(getLocale(), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: "percent",
                  }),
                  winrateColor: getWinRateColor(winrate * 100),
                  isSelected,
                  initiateImport() {
                    if (isLoadingSkeleton) return;
                    inGameState.selectedBuildKey = listKey;
                    sendInteractionEvent("champ-select-build-select", {
                      type: "playstyle",
                    });
                  },
                };
              })
              .slice(0, MAX_BUILDS_SHOWN);

            return computed({
              icon,
              title,
              style,
              buildsList,
            });
          },
          matchupBuilds() {
            const matchupBuilds = (derivedState.matchupBuilds ?? []).filter(
              ({ error }) => !error,
            );

            const buildsList = matchupBuilds.map((hash) => {
              const { buildKey, index, build } = hash;
              const { role, matchupChampionId } = buildDetails(buildKey);
              const {
                runePages = [],
                wins,
                games,
                items_completed = [],
              } = build;
              const listKey = `${buildKey}${BUILD_DELIMITER}${index}`;
              const isLoadingSkeleton = hash.isSkeleton;

              const mainItems = items_completed
                .slice(0, 3)
                .filter((item) => !BOOTS[item]);

              const isSelected =
                listKey === derivedState.selectedBuild?.listKey;

              const winrate = wins / (games || 1);

              const patch = getCurrentPatchForStaticData();
              const champion = getStaticChampionById(matchupChampionId, patch);

              const { keystoneId, subStyleId } = runePages[0] || {};

              return {
                id: listKey,
                title: champion?.name || t("common:matchup", "Matchup"),
                tVersus: t("lol:vs", "vs"),
                isLowCount: games <= 10,
                roleIcon: getRoleIcon(role, true),
                champAvatar: Static.getChampionImage(matchupChampionId),
                subtitle: t("lol:countGame_plural", "{{count}} Games", {
                  count: games.toLocaleString(getLocale()),
                }),
                keystone: Static.getRuneImage(keystoneId),
                secondaryTree:
                  subStyleId && !isLoadingSkeleton
                    ? Static.getRuneImage(subStyleId)
                    : null,
                mainItems: mainItems.length
                  ? mainItems.map((itemId) => {
                      return {
                        src: Static.getItemImage(itemId),
                      };
                    })
                  : null,
                winrate: winrate.toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  style: "percent",
                }),
                winrateColor: getWinRateColor(winrate * 100),
                isSelected,
                initiateImport() {
                  inGameState.selectedBuildKey = listKey;
                  sendInteractionEvent("champ-select-build-select", {
                    type: "matchup",
                  });
                },
              };
            });

            if (!didBuildsError(matchupBuilds) && !matchupBuilds.length)
              return null;

            return computed({
              icon: didBuildsError(matchupBuilds)
                ? genericErrorIcon
                : !matchupBuilds.length
                  ? noMatchupsError
                  : null,
              title: t("lol:builds.matchupBuilds", "Matchup Builds"),
              buildsList,
            });
          },
          competitiveBuilds() {
            const { enabled } = lolRefs.inGameFeatures;

            if (!enabled) return null;

            const competitiveBuilds = (
              derivedState.competitiveBuilds ?? []
            ).filter(({ error }) => !error);

            if (!competitiveBuilds.length) return null;

            const buildsList = competitiveBuilds.map((hash) => {
              const { buildKey, index, build } = hash;
              const { runePages = [], items_completed, misc = {} } = build;
              const listKey = `${buildKey}${BUILD_DELIMITER}${index}`;
              const isLoadingSkeleton = hash.isLoadingSkeleton;

              const mainItems = [
                items_completed[0],
                items_completed[1],
                items_completed[2],
              ].filter((itemId) => itemId && !BOOTS[itemId]);

              const isSelected =
                listKey === derivedState.selectedBuild?.listKey;

              const { keystoneId, subStyleId } = runePages[0] || {};

              return {
                title: misc.player?.name,
                timestamp: timeAgo(misc.timestamp),
                proImg: misc.playerImage,
                keystone: Static.getRuneImage(keystoneId),
                secondaryTree:
                  subStyleId && !isLoadingSkeleton
                    ? Static.getRuneImage(subStyleId)
                    : null,
                mainItems: mainItems.length
                  ? mainItems.map((itemId) => {
                      return {
                        src: Static.getItemImage(itemId),
                      };
                    })
                  : null,
                class: "item",
                vs: t("common:vs", "vs"),
                opponentImg: Static.getChampionImage(misc.opponentChampion),
                isSelected,
                initiateImport() {
                  inGameState.selectedBuildKey = listKey;
                  sendInteractionEvent("champ-select-build-select", {
                    type: "competitive",
                  });
                },
              };
            });

            return {
              icon: !competitiveBuilds.length ? genericErrorIcon : null,
              title: t("lol:builds.competitiveMatches", "Competitive Matches"),
              buildsList,
            };
          },
          proBuilds() {
            const { proBuildsError, roleBuildsLoading } = inGameState;
            const { proBuilds = [] } = derivedState;

            let title = t("lol:builds.proBuilds", "Pro Builds");
            let style = null;

            const list = proBuilds?.length
              ? proBuilds.filter(({ error }) => !error)
              : !proBuildsError && roleBuildsLoading
                ? Array(6).fill(BUILD_HASH_DEFAULT)
                : proBuilds || [];

            if (proBuildsError) {
              title = t(
                "lol:builds.proBuildsError",
                "Pro Builds Loading Error",
              );
              style = "--section-color: var(--primary)";
            }

            const buildsList = list
              .filter(
                (hash) => hash.isSkeleton || hash.build?.skills?.length >= 7,
              )
              .map((hash) => {
                const { buildKey, index, build } = hash;
                const {
                  runePages = [],
                  items_completed = [],
                  misc = {},
                } = build;
                const listKey = `${buildKey}${BUILD_DELIMITER}${index}`;
                const isLoadingSkeleton = hash.isSkeleton;

                const mainItems = [
                  items_completed[0],
                  items_completed[1],
                  items_completed[2],
                ].filter((itemId) => itemId && !BOOTS[itemId]);

                const isSelected =
                  listKey === derivedState.selectedBuild?.listKey;

                const teamImg =
                  misc.player?.team?.pictureUrl &&
                  misc.player?.team?.pictureUrl !== "streamers.png" &&
                  Static.getProTeamImage(misc.player?.team?.pictureUrl);

                const { keystoneId, subStyleId } = runePages[0] || {};

                return {
                  isLoadingSkeleton,
                  when: Math.floor(new Date(misc.timestamp)?.getTime() || 0),
                  title: !isLoadingSkeleton
                    ? misc.player?.name
                    : t("common:loading", "Loading..."),
                  subtitle: isLoadingSkeleton ? "---" : null,
                  timestamp: timeAgo(misc.timestamp),
                  isOldPatch: isOldPatch(build.misc?.patch),
                  proImg: misc.playerImage || null,
                  hasTeam: Boolean(teamImg),
                  teamImg: teamImg || null,
                  fame: proFame(misc.playerFame ?? 0),
                  fameNum: misc.playerFame ?? 0,
                  keystone:
                    keystoneId && !isLoadingSkeleton
                      ? Static.getRuneImage(keystoneId)
                      : null,
                  secondaryTree:
                    subStyleId && !isLoadingSkeleton
                      ? Static.getRuneImage(subStyleId)
                      : null,
                  mainItems: mainItems.length
                    ? mainItems.map((itemId) => {
                        return {
                          src: Static.getItemImage(itemId),
                        };
                      })
                    : null,
                  class: "item",
                  vs: t("common:vs", "vs"),
                  opponentImg:
                    misc.opponentChampion && !isLoadingSkeleton
                      ? Static.getChampionImage(misc.opponentChampion)
                      : null,
                  isSelected,
                  initiateImport() {
                    if (isLoadingSkeleton) return;
                    inGameState.selectedBuildKey = listKey;
                    sendInteractionEvent("champ-select-build-select", {
                      type: "probuild",
                    });
                  },
                };
              })
              .sort((a, b) => {
                // Sort by famous players first
                return b.fameNum - a.fameNum;
              });

            return {
              icon: null,
              title,
              style,
              buildsList,
            };
          },
          selectedBuild() {
            if (!inGameState.currentState) return null;
            const { buildSort, selectedBuild, role, localPlayerChampionId } =
              derivedState;
            const { enabled } = lolRefs.inGameFeatures;

            if (isArena) return null;
            if (buildSort === BUILD_SORT.pro && !enabled) return null;

            const hash = selectedBuild || BUILD_HASH_DEFAULT;

            if (hash.error) return null; // TODO: Render an error state/retry btn

            // TODO: handle these better, ensure the default hash is still rendered
            // but ALTER/CALL OUT more directly to do these things
            const warning = !localPlayerChampionId
              ? {
                  text: t(
                    "lol:builds.selectAChampion",
                    "Please select a Champion",
                  ),
                  icon: "",
                }
              : !isARAM && !role
                ? {
                    text: t(
                      "lol:builds.selectARole",
                      "Please select a role at the top right",
                    ),
                    icon: "",
                  }
                : {
                    text: "",
                    icon: "",
                  };

            return {
              runes: formatBuildRunes(hash),
              skillOrder: formatSkillOrder(hash.build),
              summonerSpells: formatBuildSummonerSpells(hash),
              buildItems: formatBuildItems(hash),
              warning,
              buildPatch: hash.build?.misc?.patch
                ? {
                    text: t(
                      "lol:buildPatchVersion",
                      "Build Patch {{version}}",
                      {
                        version: hash.build.misc.patch,
                      },
                    ),
                  }
                : null,
            };
          },
          premiumBenefits() {
            if (!inGameState.currentState) return null;
            const { buildSort } = derivedState;
            const { content, enabled } = lolRefs.inGameFeatures;

            if (enabled || !content || buildSort !== BUILD_SORT.pro)
              return null;

            return {
              ctaCopy: t(content.unlockCopy),
              ctaLink: content.unlockLink,
              sections: content.unlockSections.map((section) => ({
                number: section.number,
                title: t(section.title),
                description: t(section.description),
                style: `--bg-img: url(${section.image})`,
              })),
            };
          },
        });
      },

      suggestions() {
        if (!inGameState.currentState || isARAM || isArena) return null;

        return computed({
          hide() {
            return false;
          },
          title() {
            return t("lol:suggestions.suggestions", "Suggestions");
          },
          loading() {
            return !derivedState.suggestionsRoot;
          },
          suggestionsLoading() {
            if (!inGameState.currentState) return null;
            const root = derivedState.suggestionsRoot;
            const error = false; // TODO: fix

            return {
              isLoading: !root,
              didError: !!error,
              text: !error
                ? t(
                    "lol:suggestions.loadingData",
                    "Loading Suggestions Data...",
                  )
                : t(
                    "lol:suggestions.loadingDataError",
                    "Error Loading Suggestions Data 😞",
                  ),
              subtext: error,
            };
          },
          suggestionsPhase() {
            return inGameState.currentState?.phase?.type;
          },

          // Search
          handleSearch(e) {
            e.preventDefault();
            inGameState.options.suggestionsSearch = e.target.value;
          },
          clearSearch(e) {
            e.preventDefault();
            inGameState.options.suggestionsSearch = "";
          },
          searchText() {
            return inGameState.options.suggestionsSearch;
          },
          searchResults() {
            if (!inGameState.currentState) return null;
            const { currentState, options } = inGameState;
            const { availableIds } = derivedState;
            const root = derivedState.suggestionsRoot;
            const searchText = options.suggestionsSearch;
            const bannedIds = currentState?.bannedIds;

            if (!root || (root && !searchText)) return null;

            const searchResults = (root.ALL || [])
              .filter(
                (s) =>
                  s.id &&
                  availableIds[s.id] &&
                  (s.name || s.key || "")
                    .toLowerCase()
                    .includes(searchText.toLowerCase()),
              )
              .sort((a, b) => a.name.localeCompare(b.name));

            if (!searchResults.length) return null;

            return computed({
              open: true,
              title: t("common:searchResults", "Search Results"),
              hasResults: !!searchResults.length,
              list: searchResults.map((s) => {
                return computed({
                  id: s.id,
                  onClick: () => instaBanPick(s.id, "list-search"),
                  lockinCopy: t(
                    "lol:suggestions.lockInName",
                    "Lock In {{name}}",
                    {
                      name: s.name || "",
                    },
                  ),
                  name: s.name,
                  image: s.image,
                  banned: !!bannedIds[s.id],
                  subtext1: t("lol:countGame_plural", "{{count}} Games", {
                    count: s.roleMeta.gameCount.toLocaleString(getLocale()),
                  }),
                  stat1: {
                    value: s.roleMeta.winRate.toLocaleString(getLocale(), {
                      style: "percent",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                    text: "Game",
                    style: `--stat-color: ${getWinRateColor(
                      s.roleMeta.winRate * 100,
                    )}`,
                  },
                });
              }),
            });
          },
          noSearchResults() {
            if (!inGameState.currentState) return null;
            const root = derivedState.suggestionsRoot;
            const searchText = inGameState.options.suggestionsSearch;
            if (!root || (root && !searchText)) return null;

            const searchResults = (root.ALL || []).filter((s) =>
              (s.name || s.key)
                .toLowerCase()
                .includes(searchText.toLowerCase()),
            );

            if (!searchText || searchResults.length) return null;

            return computed({
              title: t("common:noResults", "No results"),
              subtitle: t(
                "lol:suggestions.noSearchResultsSubtitle",
                "What you typed yielded no results. Check the spelling and see if you mispelled something.",
              ),
            });
          },
          searchActive() {
            return inGameState.options.suggestionsSearch.length > 0;
          },

          // Ban Suggestions
          declaredCounters() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;
            const { roleMatchupsUpdatedAt } = inGameData;

            const hasData = !!roleMatchupsUpdatedAt;
            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase?.type;
            const myChampionID = derivedState.localPlayerChampionId;
            const more = options.declaredLaneCounterMore;
            const amt = more ? max : min;

            const titleImgSrc =
              myChampionID && Static.getChampionImage(myChampionID);

            const patch = getCurrentPatchForStaticData();
            const champion = getStaticChampionById(myChampionID, patch);
            const name = champion?.name;

            const list = derivedState.bansCounters;

            if (
              !isBanPhase(phase) ||
              (isBanPhase(phase) && !myChampionID) ||
              searchText.length
            )
              return null;

            return computed({
              open: true,
              title: !hasData
                ? t(
                    "lol:suggestions.dataFailedMatchups",
                    "Matchups failed to load",
                  )
                : name
                  ? t(
                      "lol:suggestions.counterstoChampion",
                      "Counters to {{name}}",
                      { name },
                    )
                  : t(
                      "lol:suggestions.declareAChampion",
                      "Declare a champion...",
                    ),
              titleImg: {
                src: titleImgSrc,
              },
              dataMissing: !hasData,
              hasResults: !!(myChampionID && list.length),
              list: list
                .slice(0, amt)
                .map((s) => {
                  const matchup = s.matchups[myChampionID];
                  if (!matchup) return null;
                  const laneWR = matchup.laneWins / (matchup.games || 1);
                  const gameWR = matchup.wins / (matchup.games || 1);

                  return computed({
                    id: s.id,
                    onClick: () => instaBanPick(s.id, "list-declared-counters"),
                    lockinCopy: t("lol:suggestions.banName", "Ban {{name}}", {
                      name: s.name || "",
                    }),
                    name: s.name,
                    image: s.image,
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: matchup.games.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: gameWR.toLocaleString(getLocale(), {
                        style: "percent",
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: t("lol:championData.game", "Game"),
                    },
                    stat2: {
                      value: laneWR.toLocaleString(getLocale(), {
                        style: "percent",
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: t("lol:championData.lane", "Lane"),
                      style: `--stat-color: ${getWinRateColor(
                        (1 - laneWR) * 100,
                      )}`,
                    },
                  });
                })
                .filter(Boolean),
              showMore:
                list.length > min
                  ? {
                      click: (e) => {
                        e.preventDefault();
                        options.declaredLaneCounterMore =
                          !options.declaredLaneCounterMore;
                      },
                      text: more
                        ? t("common:showLess", "Show less")
                        : t("common:showMore", "Show more"),
                    }
                  : null,
            });
          },
          metaBans() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;
            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase?.type;
            const more = options.metaBansMore;
            const amt = more ? max : min;
            const list = derivedState.bansMetaBans;

            if (!isBanPhase(phase) || searchText.length) return null;

            return computed({
              open: true,
              title: t("lol:suggestions.bansTierList", "Bans Tier List"),
              titleImg: null,
              hasResults: !!list.length,
              list: list
                .slice(0, amt)
                .map((s) => {
                  if (!s.roleMeta.tier) return null;
                  return computed({
                    id: s.id,
                    onClick: () => instaBanPick(s.id, "list-bans-meta"),
                    lockinCopy: t("lol:suggestions.banName", "Ban {{name}}", {
                      name: s.name || "",
                    }),
                    name: s.name,
                    image: s.image,
                    metaTier: s.roleMeta.tier && {
                      tierIcon: `<img src="${getTierIcon(s.roleMeta.tier)}" width="20" height="20" class="tier-icon" />`,
                    },
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: s.roleMeta.gameCount.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: s.roleMeta.winRate.toLocaleString(getLocale(), {
                        style: "percent",
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: "Game",
                      style: `--stat-color: ${getWinRateColor(
                        s.roleMeta.winRate * 100,
                      )}`,
                    },
                  });
                })
                .filter(Boolean),
              showMore:
                list.length > min
                  ? {
                      click: (e) => {
                        e.preventDefault();
                        options.metaBansMore = !options.metaBansMore;
                      },
                      text: more
                        ? t("common:showLess", "Show less")
                        : t("common:showMore", "Show more"),
                    }
                  : null,
            });
          },
          teammatesCounters() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;
            const { roleMatchupsUpdatedAt } = inGameData;

            const root = derivedState.suggestionsRoot;
            const hasData = !!roleMatchupsUpdatedAt;
            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase?.type;

            const patch = getCurrentPatchForStaticData();

            const teammateGroups = derivedState.bansTeammatesSynergies.map(
              (s, i) => {
                const { championId, role, list } = s;
                const teammateID = championId;
                const more = options.teammatesCountersMore[`${i}_more`];
                const amt = more ? max : min;

                const teammateRole = role;
                const champion = getStaticChampionById(teammateID, patch);
                const name = champion?.name;

                const hasTeammateCounters = list.some(
                  (s) => !!s.matchups[teammateID],
                );

                return computed({
                  open: true,
                  style: `
                    display: ${!hasTeammateCounters ? "none" : "block"};
                  `,
                  title: !hasData
                    ? t(
                        "lol:suggestions.dataFailedMatchups",
                        "Matchups failed to load",
                      )
                    : name || teammateRole
                      ? t(
                          "lol:suggestions.countersToTeammateChampion",
                          "Counters to {{name}}",
                          { name: name || teammateRole },
                        )
                      : t(
                          "lol:suggestions.countersToTeammate",
                          "Counters to teammate",
                        ),
                  titleImg: {
                    src: teammateID && Static.getChampionImage(teammateID),
                  },
                  dataMissing: !hasData,
                  list: list
                    .slice(0, amt)
                    .map((s) => {
                      const matchup = s.matchups[teammateID];
                      if (!matchup) return null;
                      const laneWR =
                        1 - matchup.laneWins / (matchup.games || 1);

                      return computed({
                        id: s.id,
                        onClick: () =>
                          instaBanPick(s.id, "list-bans-teammate-counters"),
                        lockinCopy: t(
                          "lol:suggestions.banName",
                          "Ban {{name}}",
                          {
                            name: s.name || "",
                          },
                        ),
                        name: s.name,
                        image: s.image,
                        subtext1: t("lol:countGame_plural", "{{count}} Games", {
                          count: matchup.games.toLocaleString(getLocale()),
                        }),
                        stat1: {
                          value: laneWR.toLocaleString(getLocale(), {
                            style: "percent",
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }),
                          text: t("lol:championData.lane", "Lane"),
                          style: `--stat-color: ${getWinRateColor(
                            laneWR * 100,
                          )}`,
                        },
                      });
                    })
                    .filter(Boolean),
                  showMore:
                    list.length > min
                      ? {
                          click: (e) => {
                            e.preventDefault();
                            options.teammatesCountersMore[`${i}_more`] =
                              !options.teammatesCountersMore[`${i}_more`];
                            sendInteractionEvent(
                              "champ-select-suggestion-view-more",
                              {
                                type: "ban",
                                ui: "teammate counters",
                              },
                            );
                          },
                          text: more
                            ? t("common:showLess", "Show less")
                            : t("common:showMore", "Show more"),
                        }
                      : null,
                });
              },
            );

            if (!root || !isBanPhase(phase) || searchText.length) return null;

            return teammateGroups;
          },

          // Pick Suggestions
          pickCounters() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;
            const { roleMatchupsUpdatedAt } = inGameData;

            const hasData = !!roleMatchupsUpdatedAt;
            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase?.type;
            const enemyID = derivedState.enemyLaner;
            const more = options.laneCounterMore;
            const amt = more ? max : min;

            const titleImgSrc = enemyID && Static.getChampionImage(enemyID);
            const list = derivedState.picksCounters;

            if (isBanPhase(phase) || isDeclarePhase(phase) || searchText.length)
              return null;

            const champion = getStaticChampionById(
              enemyID,
              getCurrentPatchForStaticData(),
            );
            const name = champion?.name;

            return computed({
              open: true,
              title: !hasData
                ? t(
                    "lol:suggestions.dataFailedMatchups",
                    "Matchups failed to load",
                  )
                : !enemyID
                  ? t("lol:suggestions.noLaneOpponent", "No lane opponent")
                  : enemyID && !list.length
                    ? t("lol:suggestions.noMatchups", "No matchups")
                    : t(
                        "lol:suggestions.championNameCounters",
                        "{{name}} Counters",
                        { name },
                      ),
              titleImg: {
                src: titleImgSrc,
              },
              dataMissing: !hasData,
              hasResults: !!(enemyID && list.length),
              list: list.slice(0, amt).map((s) => {
                const matchup = s.matchups[enemyID];
                if (!matchup) return null;
                const laneWR = matchup.laneWins / (matchup.games || 1);
                const gameWR = matchup.wins / (matchup.games || 1);

                return computed({
                  id: s.id,
                  onClick: () =>
                    instaBanPick(s.id, "list-bans-ownpick-counters"),
                  lockinCopy: t(
                    "lol:suggestions.lockInName",
                    "Lock In {{name}}",
                    {
                      name: s.name || "",
                    },
                  ),
                  name: s.name,
                  image: s.image,
                  subtext1: t("lol:countGame_plural", "{{count}} Games", {
                    count: matchup.games.toLocaleString(getLocale()),
                  }),
                  stat1: {
                    value: gameWR.toLocaleString(getLocale(), {
                      style: "percent",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                    text: t("lol:championData.game", "Game"),
                    style: `--stat-color: ${getWinRateColor(gameWR * 100)}`,
                  },
                  stat2: {
                    value: laneWR.toLocaleString(getLocale(), {
                      style: "percent",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                    text: t("lol:championData.lane", "Lane"),
                    style: `--stat-color: ${getWinRateColor(laneWR * 100)}`,
                  },
                });
              }),
              showMore:
                list.length > min
                  ? {
                      click: (e) => {
                        e.preventDefault();
                        options.laneCounterMore = !options.laneCounterMore;
                        sendInteractionEvent(
                          "champ-select-suggestion-view-more",
                          {
                            type: "pick",
                            ui: "LANE counters",
                          },
                        );
                      },
                      text: more
                        ? t("common:showLess", "Show less")
                        : t("common:showMore", "Show more"),
                    }
                  : null,
            });
          },
          career() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;

            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase.type;
            const more = options.careerMore;
            const amt = more ? max : min;

            const list = derivedState.picksCareer;

            if (!list?.length || isBanPhase(phase) || searchText.length)
              return null;

            return computed({
              open: true,
              title: t("lol:suggestions.careerBest", "Your Personal Best"),
              hasResults: !!list.length,
              list: list
                .slice(0, amt)
                .map((s) => {
                  const career = s.career;
                  if (!career) return null;
                  const gameWR = career.wins / (career.games || 1);

                  return computed({
                    id: s.id,
                    onClick: () => instaBanPick(s.id, "list-career-best"),
                    lockinCopy: t(
                      "lol:suggestions.lockInName",
                      "Lock In {{name}}",
                      {
                        name: s.name || "",
                      },
                    ),
                    name: s.name,
                    image: s.image,
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: career.games.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: gameWR.toLocaleString(getLocale(), {
                        style: "percent",
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: t("lol:championData.game", "Game"),
                      style: `--stat-color: ${getWinRateColor(gameWR * 100)}`,
                    },
                  });
                })
                .filter(Boolean),
              showMore:
                list.length > min
                  ? {
                      click: (e) => {
                        e.preventDefault();
                        inGameState.options.careerMore =
                          !inGameState.options.careerMore;
                      },
                      text: more
                        ? t("common:showLess", "Show less")
                        : t("common:showMore", "Show more"),
                    }
                  : null,
            });
          },
          metaPicks() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;

            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase?.type;
            const more = options.metaPicksMore;
            const amt = more ? max : min;

            const list = derivedState.picksMetaPicks;

            if (isBanPhase(phase) || searchText.length) return null;

            return computed({
              open: true,
              title: t("lol:suggestions.picksTierList", "Picks Tier List"),
              titleImg: null,
              hasResults: !!list.length,
              list: list
                .slice(0, amt)
                .map((s) => {
                  if (!s.roleMeta.tier) return null;
                  return computed({
                    id: s.id,
                    onClick: () => instaBanPick(s.id, "list-picks-meta"),
                    lockinCopy: t(
                      "lol:suggestions.lockInName",
                      "Lock In {{name}}",
                      {
                        name: s.name || "",
                      },
                    ),
                    name: s.name,
                    image: s.image,
                    metaTier: s.roleMeta.tier && {
                      tierIcon: `<img src="${getTierIcon(s.roleMeta.tier)}" width="20" height="20" class="tier-icon" />`,
                    },
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: s.roleMeta.gameCount.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: s.roleMeta.winRate.toLocaleString(getLocale(), {
                        style: "percent",
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: "Game",
                      style: `--stat-color: ${getWinRateColor(
                        s.roleMeta.winRate * 100,
                      )}`,
                    },
                  });
                })
                .filter(Boolean),
              showMore:
                list.length > min
                  ? {
                      click: (e) => {
                        e.preventDefault();
                        options.metaPicksMore = !options.metaPicksMore;
                      },
                      text: more
                        ? t("common:showLess", "Show less")
                        : t("common:showMore", "Show more"),
                    }
                  : null,
            });
          },
          teammatesSynergies() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;
            const { synergiesUpdatedAt } = inGameData;
            const myRole = derivedState.role;

            const root = derivedState.suggestionsRoot;
            const hasData = !!synergiesUpdatedAt;
            const searchText = options.suggestionsSearch;
            const phase = currentState?.phase?.type;

            const patch = getCurrentPatchForStaticData();

            const pair = ROLE_PAIRS[RoleSymbol(myRole)];
            const rolePair = ROLE_SYMBOL_TO_STR[pair]?.internal;

            const teammateGroups = derivedState.picksTeammatesSynergies
              .map((s, i) => {
                const { championId, roleSymbol, list } = s;
                const teammateID = championId;
                const more = options.teammatesSynergiesMore[`${i}_more`];
                const amt = more ? max : min;

                const teammateRole = ROLE_SYMBOL_TO_STR[roleSymbol]?.internal;
                const champion = getStaticChampionById(teammateID, patch);
                const name = champion?.name;
                const isPair = teammateRole === rolePair;

                const hasSynergies = list.some(
                  (s) => !!s.synergies[teammateID],
                );

                return computed({
                  pair: isPair ? 1 : 0,
                  style: `
                    order: ${isPair ? "0" : "1"};
                    display: ${!hasData || !hasSynergies ? "none" : "block"};
                  `,
                  open: isPair || null,
                  title:
                    name || teammateRole
                      ? t(
                          "lol:suggestions.synergiesWithTeammateChampion",
                          "Synergies with {{name}}",
                          { name: name || teammateRole },
                        )
                      : t(
                          "lol:suggestions.synergiesWithAlly",
                          "Synergies with ally",
                        ),
                  titleImg: {
                    src: teammateID && Static.getChampionImage(teammateID),
                  },
                  list: list
                    .slice(0, amt)
                    .map((s) => {
                      const synergy = s.synergies[teammateID];
                      if (!synergy) return null;
                      const gameWR = synergy.wins / (synergy.games || 1);

                      return computed({
                        id: s.id,
                        onClick: () =>
                          instaBanPick(s.id, "list-picks-teammate-synergies"),
                        lockinCopy: t(
                          "lol:suggestions.lockInName",
                          "Lock In {{name}}",
                          {
                            name: s.name || "",
                          },
                        ),
                        name: s.name,
                        image: s.image,
                        subtext1: t("lol:countGame_plural", "{{count}} Games", {
                          count: synergy.games.toLocaleString(getLocale()),
                        }),
                        stat1: {
                          value: gameWR.toLocaleString(getLocale(), {
                            style: "percent",
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }),
                          text: t("lol:championData.game", "Game"),
                          style: `--stat-color: ${getWinRateColor(
                            gameWR * 100,
                          )}`,
                        },
                      });
                    })
                    .filter(Boolean),
                  showMore:
                    list.length > min
                      ? {
                          click: (e) => {
                            e.preventDefault();
                            options.teammatesSynergiesMore[`${i}_more`] =
                              !options.teammatesSynergiesMore[`${i}_more`];
                          },
                          text: more
                            ? t("common:showLess", "Show less")
                            : t("common:showMore", "Show more"),
                        }
                      : null,
                });
              })
              .sort((a, b) => b.pair - a.pair);

            if (!root || isBanPhase(phase) || searchText.length) return null;

            return teammateGroups;
          },

          nothingSelected() {
            if (!inGameState.currentState) return null;
            const { options, currentState } = inGameState;
            const {
              suggestionsHoveredId,
              suggestionsClickedBanId,
              suggestionsClickedPickId,
            } = options;
            const { summonersByCellId, localPlayerCellId } = currentState;

            const localPlayer = summonersByCellId[localPlayerCellId];
            const phase = currentState.phase.type;

            const enemyID = derivedState.enemyLaner;
            const pairID = derivedState.pairTeammate;

            const isBanning = isBanPhase(phase);

            const list = isBanning
              ? derivedState.bansPersonalized
              : derivedState.picksPersonalized;

            const title = isBanning
              ? t("lol:suggestions.placeholderBanTitle", "Meta ban suggestions")
              : t(
                  "lol:suggestions.placeholderPickTitle",
                  "Real-time personalized pick suggestions",
                );

            const active =
              phase !== INGAME_PHASES[0] && localPlayer.isInProgress;
            const activeId =
              suggestionsHoveredId ||
              (phase === INGAME_PHASES[1] && suggestionsClickedBanId) ||
              (phase === INGAME_PHASES[2] && suggestionsClickedPickId);

            if (activeId) return null;

            const teammateChampions = Object.values(
              derivedState.teammates || {},
            ).reduce((acc, curr) => {
              acc[curr.championId] = true;
              return acc;
            }, {});

            const preferredBans = Array.from({ length: 4 })
              .map((_, i) => {
                const banId = derivedState.localPlayerBans[i];
                const champion = getStaticChampionById(banId);

                return banId
                  ? {
                      id: banId,
                      champion,
                    }
                  : null;
              })
              .filter(Boolean);

            return computed({
              hide: !derivedState.suggestionsRoot,
              title,
              role: derivedState.role,
              enemyLaner: Static.getChampionImage(enemyID),
              pairTeammate: Static.getChampionImage(pairID),
              subtitle: isBanning
                ? t(
                    "lol:suggestions.placeholderBanSuggestions",
                    "The strongest popular champions in your role.",
                  )
                : t(
                    "lol:suggestions.placeholderPickSuggestions",
                    "Updates in real-time based on the meta, your stats, teammate picks, and lane opponent.",
                  ),
              listTitle: isBanning
                ? t("lol:suggestions.banSuggestions", "Ban Suggestions")
                : t(
                    "lol:suggestions.personalizedPickSuggestions",
                    "Personalized Pick Suggestions",
                  ),
              list: list.slice(0, 6).map((s, i) => ({
                id: s.id,
                btnClick: () => instaBanPick(s.id, "personal"),
                btnDisabled: !active,
                name: s.name,
                image: s.image,
                subtitle: t(
                  "lol:suggestions.numSuggestion",
                  "#{{num}} Suggestion",
                  {
                    num: i + 1,
                  },
                ),
                metaTier: s.roleMeta.tier <= 4 && {
                  tierIcon: `<img src="${getTierIcon(s.roleMeta.tier)}" width="28" height="28" class="tier-icon" />`,
                },
                style: s.roleMeta.tier
                  ? `--tier-color: var(--tier${s.roleMeta.tier});`
                  : "--tier-color: var(--shade3);",
                btnText: isBanning
                  ? t("lol:suggestions.ban", "Ban")
                  : t("lol:lockIn", "Lock In"),
              })),
              bansPreferred: isBanning
                ? {
                    title: t(
                      "lol:suggestions.favoriteBansTitle",
                      "Your Favorite Bans",
                    ),
                    subtitle: preferredBans.length
                      ? t(
                          "lol:suggestions.favoriteBansSubtitle",
                          "Easy access to the champions you ban the most",
                        )
                      : t(
                          "lol:suggestions.favoriteBansSubtitleEmpty",
                          "As you ban with Blitz open we'll save them for you here",
                        ),
                    list: Array.from({ length: 4 }).map((_, i) => {
                      const { id: banId, champion } = preferredBans[i] || {};
                      const isTeammateChamp = teammateChampions[banId] || false;
                      const disabled = !banId || isTeammateChamp;

                      return {
                        image: Static.getChampionImage(banId),
                        empty: !banId,
                        teammate: isTeammateChamp,
                        closeIcon: Close.svg,
                        warningIcon: isTeammateChamp ? Warning.svg : null,
                        style: `z-index: ${10 - i}`,
                        onClick: () => {
                          if (disabled) return;
                          instaBanPick(banId, "favorite", "ban");
                        },
                        onContextMenu: () => {
                          options.removedBannedChampions[banId] = banId;
                          inGameState.bansUpdatesAt = Date.now();
                        },
                        tooltipPlacement: "left",
                        tooltip: isTeammateChamp
                          ? `
                          <div>
                            <p class="type-body1">
                              ${t(
                                "lol:suggestions.cannotBanTeammate",
                                "Cannot ban teammate's champion",
                              )}
                            </p>
                          </div>
                        `
                          : champion
                            ? `
                          <div>
                            <p class="type-body1">
                              ${t(
                                "lol:suggestions.banChampionName",
                                "Ban {{name}}",
                                { name: champion.name },
                              )}
                            </p>
                            <p class="type-caption color-shade2">
                              ${t(
                                "lol:suggestions.clickToBanChampionName",
                                "Click to immediately ban {{name}}",
                                { name: champion.name },
                              )}
                            </p>
                            <p class="type-caption color-shade2">
                              ${t(
                                "common:rightClickToRemove",
                                "Right click to remove",
                              )}
                            </p>
                          </div>
                        `
                            : null,
                      };
                    }),
                  }
                : null,
            });
          },
        });
      },

      arenaBuild() {
        if (!inGameState.currentState || !isArena) return null;
        const { selectedBuild } = derivedState;

        const build = selectedBuild?.build || {};

        const {
          items_starting,
          items_completed,
          items_prismatic,
          items_boots,
          stats,
        } = build;

        const itemsStats = stats?.items || {};

        const items = [
          {
            title: t("lol:championData.prismatics", "Prismatics"),
            list: items_prismatic,
            isPrismatic: true,
          },
          {
            title: t("lol:championData.commonItems", "Common Items"),
            list: items_completed,
          },
          {
            title: t("lol:championData.starting", "Starting"),
            list: items_starting,
          },
          {
            title: t("lol:itemsets.bootsOptions", "Boots Options"),
            list: items_boots,
          },
        ];

        return computed({
          items: items.map((grp) => ({
            title: grp.title,
            list: (grp.list || []).map((id) => ({
              src: Static.getItemImage(id),
              stats: itemsStats[id],
              isPrismatic: grp.isPrismatic,
              tierIcon: getTierIcon(itemsStats[id]?.tier, false),
              tooltip: getItemTooltip(id),
              pickRate: t("lol:percentPickRate", "{{pickrate}}% Pick Rate", {
                pickrate: (itemsStats[id]?.pick_rate * 100).toLocaleString(
                  getLocale(),
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                ),
              }),
              // topFourRate: t(
              //   "lol:percentTopFourRate",
              //   "{{topFourRate}}% Top 4",
              //   {
              //     topFourRate: (
              //       itemsStats[id]?.top_4_percent * 100
              //     ).toLocaleString(getLocale(), {
              //       minimumFractionDigits: 0,
              //       maximumFractionDigits: 0,
              //     }),
              //   },
              // ),
              width: grp.isPrismatic ? "56" : "44",
              height: grp.isPrismatic ? "56" : "44",
            })),
          })),
        });
      },

      arenaSuggestions() {
        if (!inGameState.currentState || !isArena) return null;
        const phase = inGameState.currentState?.phase?.type;
        const isBanning = isBanPhase(phase);

        return computed({
          favoriteBans() {
            if (!inGameState.currentState || !isArena) return null;
            const { options } = inGameState;

            const phase = inGameState.currentState.phase.type;

            if (!isBanPhase(phase)) return null;

            const count = 8;

            const preferredBans = Array.from({ length: count })
              .map((_, i) => {
                const banId = derivedState.localPlayerBans[i];
                const champion = getStaticChampionById(banId);

                return banId
                  ? {
                      id: banId,
                      champion,
                    }
                  : null;
              })
              .filter(Boolean);

            return {
              title: t(
                "lol:suggestions.favoriteBansTitle",
                "Your Favorite Bans",
              ),
              subtitle: preferredBans.length
                ? t(
                    "lol:suggestions.favoriteBansSubtitle",
                    "Easy access to the champions you ban the most",
                  )
                : t(
                    "lol:suggestions.favoriteBansSubtitleEmpty",
                    "As you ban with Blitz open we'll save them for you here",
                  ),
              list: Array.from({ length: count }).map((_, i) => {
                const { id: banId, champion } = preferredBans[i] || {};
                const isTeammateChamp =
                  banId === inGameState.currentState.arenaTeammateChampionId;
                const disabled = !banId || isTeammateChamp;

                return {
                  image: Static.getChampionImage(banId),
                  empty: !banId,
                  teammate: isTeammateChamp,
                  closeIcon: Close.svg,
                  warningIcon: isTeammateChamp ? Warning.svg : null,
                  style: `z-index: ${10 - i}`,
                  onClick: () => {
                    if (disabled) return;
                    instaBanPick(banId, "favorite", "ban");
                  },
                  onContextMenu: () => {
                    options.removedBannedChampions[banId] = banId;
                    inGameState.bansUpdatesAt = Date.now();
                  },
                  tooltipPlacement: "left",
                  tooltip: isTeammateChamp
                    ? `
                    <div>
                      <p class="type-body1">
                        ${t(
                          "lol:suggestions.cannotBanTeammate",
                          "Cannot ban teammate's champion",
                        )}
                      </p>
                    </div>
                  `
                    : champion
                      ? `
                    <div>
                      <p class="type-body1">
                        ${t("lol:suggestions.banChampionName", "Ban {{name}}", {
                          name: champion.name,
                        })}
                      </p>
                      <p class="type-caption color-shade2">
                        ${t(
                          "lol:suggestions.clickToBanChampionName",
                          "Click to immediately ban {{name}}",
                          { name: champion.name },
                        )}
                      </p>
                      <p class="type-caption color-shade2">
                        ${t(
                          "common:rightClickToRemove",
                          "Right click to remove",
                        )}
                      </p>
                    </div>
                  `
                      : null,
                };
              }),
            };
          },
          columnMeta() {
            if (!inGameState.currentState || !isArena) return null;
            const { options } = inGameState;
            const stats = derivedState.arenaTierlistSingles;
            const bannedIds = inGameState.currentState.bannedIds || {};
            const maxResults = options.arenaSearchSingle.length
              ? stats.length
              : 50;

            return computed({
              title: t("lol:arena.bestArenaChampions", "Best Arena Champions"),
              searchPlaceholder: t("lol:searchChampions", "Search Champions"),
              searchText: () => options.arenaSearchSingle,
              searchActive: Boolean(options.arenaSearchSingle),
              clearSearch: () => {
                options.arenaSearchSingle = "";
              },
              handleSearch: (e) => {
                e.preventDefault();
                options.arenaSearchSingle = e.target.value;
              },
              list: stats
                .slice(0, maxResults)
                .filter((s) => {
                  return !options.arenaSearchSingle.length
                    ? true
                    : (s?.championName || "")
                        .toLowerCase()
                        .includes(options.arenaSearchSingle.toLowerCase());
                })
                .map((s) => {
                  return computed({
                    id: s.id,
                    onClick: () => instaBanPick(s.id, "list-arena"),
                    lockinCopy: isBanning
                      ? t("lol:suggestions.banName", "Ban {{name}}", {
                          name: s.name || "",
                        })
                      : t("lol:suggestions.lockInName", "Lock In {{name}}", {
                          name: s.name || "",
                        }),
                    name: s.championName,
                    image: Static.getChampionImage(s.id),
                    banned: !!bannedIds[s.id],
                    metaTier: s.tier && {
                      tierIcon: `<img src="${getTierIcon(s.roleMeta.tier)}" width="20" height="20" class="tier-icon" />`,
                    },
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: s.matches.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: s.avgPlace.toLocaleString(getLocale(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: t("common:avgPlace", "Avg. Place"),
                      style: `--stat-color: ${placementColor(s.avgPlace)};`,
                    },
                  });
                }),
            });
          },
          columnWithTeammate() {
            if (!inGameState.currentState || !isArena) return null;
            const champions = getStaticData("champions");
            const { arenaTeammateChampionId } = inGameState.currentState;
            const { arenaTeammateBuild } = derivedState;
            const { options } = inGameState;
            const stats = derivedState.arenaSynergyTierlist;
            const bannedIds = inGameState.currentState.bannedIds || {};
            const maxResults = options.arenaSearchSynergy.length
              ? stats.length
              : 50;

            const teammateKey = champions?.keys?.[arenaTeammateChampionId];
            const teammateName = champions?.[teammateKey]?.name;

            const isActive = Boolean(arenaTeammateBuild) && !isBanning;

            return computed({
              active: isActive,
              waiting: !isActive
                ? !isBanning && {
                    icon: LoadingSpinner.svg,
                    text: t(
                      "common:waitingForTeammateToPick",
                      "Waiting for teammate to pick...",
                    ),
                  }
                : null,
              title: teammateName
                ? t(
                    "lol:bestSynergiesWithChampionName",
                    "Best Synergies with {{championName}}",
                    {
                      championName: teammateName,
                    },
                  )
                : t(
                    "lol:bestSynergiesWithTeammate",
                    "Best Synergies with Teammate",
                  ),
              searchPlaceholder: t("lol:searchChampions", "Search Champions"),
              searchActive: Boolean(options.arenaSearchSynergy),
              searchText: () => inGameState.options.arenaSearchSynergy,
              clearSearch: () => {
                inGameState.options.arenaSearchSynergy = "";
              },
              handleSearch: (e) => {
                e.preventDefault();
                inGameState.options.arenaSearchSynergy = e.target.value;
              },
              list: stats
                .slice(0, maxResults)
                .filter((s) => {
                  return !options.arenaSearchSynergy.length
                    ? true
                    : (s?.championName || "")
                        .toLowerCase()
                        .includes(options.arenaSearchSynergy.toLowerCase());
                })
                .map((s) => {
                  return computed({
                    id: s.id,
                    onClick: () => instaBanPick(s.id, "list-arena-synergy"),
                    lockinCopy: isBanning
                      ? t("lol:suggestions.banName", "Ban {{name}}", {
                          name: s.name || "",
                        })
                      : t("lol:suggestions.lockInName", "Lock In {{name}}", {
                          name: s.name || "",
                        }),
                    name: s.championName,
                    image: Static.getChampionImage(s.id),
                    banned: !!bannedIds[s.id],
                    metaTier: s.tier && {
                      tierIcon: `<img src="${getTierIcon(s.tier)}" width="20" height="20" class="tier-icon" />`,
                    },
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: s.matches.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: s.avgPlace.toLocaleString(getLocale(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: t("common:avgPlace", "Avg. Place"),
                      style: `--stat-color: ${placementColor(s.avgPlace)};`,
                    },
                  });
                }),
            });
          },
          columnDuos() {
            if (!inGameState.currentState || !isArena) return null;
            const { options } = inGameState;
            const stats = derivedState.arenaTierlistDuos;
            const bannedIds = inGameState.currentState.bannedIds || {};
            const maxResults = options.arenaSearchDuo.length
              ? stats.length
              : 75;

            return computed({
              title: t("lol:arena.bestArenaDuos", "Best Arena Duos"),
              searchPlaceholder: t("common:searchDuos", "Search Duos"),
              searchText: () => options.arenaSearchDuo,
              searchActive: Boolean(options.arenaSearchDuo),
              clearSearch: () => {
                options.arenaSearchDuo = "";
              },
              handleSearch: (e) => {
                e.preventDefault();
                options.arenaSearchDuo = e.target.value;
              },
              list: stats
                .filter((s) => {
                  const name =
                    s.champion1Name &&
                    s.champion2Name &&
                    `${s.champion1Name}${s.champion2Name}`;
                  if (bannedIds[s.id1] || bannedIds[s.id2]) return false;
                  return !options.arenaSearchDuo.length
                    ? true
                    : (name || "")
                        .toLowerCase()
                        .includes(options.arenaSearchDuo.toLowerCase());
                })
                .slice(0, maxResults)
                .map((s) => {
                  return computed({
                    id: `${s.id1}:${s.id2}`,
                    onClick: () => {},
                    lockinCopy: null,
                    name: `${s.champion1Name} & ${s.champion2Name}`,
                    image1: Static.getChampionImage(s.id1),
                    image2: Static.getChampionImage(s.id2),
                    banned: !!bannedIds[s.id1] || !!bannedIds[s.id2],
                    metaTier: s.tier && {
                      tierIcon: `<img src="${getTierIcon(s.tier)}" width="20" height="20" class="tier-icon" />`,
                    },
                    subtext1: t("lol:countGame_plural", "{{count}} Games", {
                      count: s.matches.toLocaleString(getLocale()),
                    }),
                    stat1: {
                      value: s.avgPlace.toLocaleString(getLocale(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                      text: t("common:avgPlace", "Avg. Place"),
                      style: `--stat-color: ${placementColor(s.avgPlace)};`,
                    },
                  });
                }),
            });
          },
        });
      },
    });
  },

  aramBench() {
    if (!inGameState.currentState) return null;

    const {
      currentState: { benchEnabled },
    } = inGameState;
    const { championStats = [] } = inGameData;

    if (!benchEnabled) return null;

    const aramStats = championStats.reduce((acc, curr) => {
      acc[curr.championId] = curr;
      return acc;
    }, {});

    return computed({
      titleTeammates() {
        return t("common:teammates", "Teammates");
      },
      titleBench() {
        return t("lol:aram.benchChampions", "Bench Champions");
      },
      teammates() {
        if (!inGameState.currentState) return null;
        const {
          currentState: {
            summonersByCellId,
            localPlayerCellId,
            myTeamCellIds = [],
          },
          pickableChampionIds,
        } = inGameState;
        const teammates = myTeamCellIds.map(
          (cellId) => summonersByCellId[cellId],
        );

        return teammates.map((s) => {
          const { cellId, championId } = s;
          const { trade } = summonersByCellId[cellId] || {};

          const stats = aramStats[championId] || {};
          const { wins = 0, games = 1 } = stats;
          const winrate = wins / games;

          return computed({
            hasChampion: !!championId,
            isPlayable: pickableChampionIds.length
              ? pickableChampionIds.includes(championId)
              : true,
            isUser: localPlayerCellId === cellId,
            image: Static.getChampionImage(championId),
            winrate: winrate ? toPercent(winrate) : null,
            highWinrate: Math.round(winrate) >= 0.5,
            hasWinrate: !!winrate,
            winrateColor: getWinRateColor(winrate * 100),
            tradeOverlay: {
              icon: Swap.svg,
            },
            tooltip: t("lol:trade.tradeChampions", "Trade Champions"),
            style: null,
            onClick: (e) => {
              e.preventDefault();
              if (trade?.state !== ARAM_TRADE_STATES.available) return;
              requestAramTrade(trade?.championId);
              sendInteractionEvent("champ-select-aram-request-trade");
            },
          });
        });
      },
      bench() {
        if (!inGameState.currentState) return null;
        const {
          currentState: { benchChampionIds = [] },
          pickableChampionIds = [],
        } = inGameState;

        const BENCH_SIZE = 10;

        const benchList = [...new Array(BENCH_SIZE)].map((_, i) => {
          const id = benchChampionIds[i];
          const stats = aramStats[id] || {};
          const { wins = 0, games = 1 } = stats;
          const winrate = wins / games;

          return computed({
            hasChampion: !!id,
            isPlayable: pickableChampionIds.length
              ? pickableChampionIds.includes(id)
              : true,
            image: id ? Static.getChampionImage(id) : null,
            winrate: toPercent(winrate),
            highWinrate: Math.round(winrate) >= 0.5,
            hasWinrate: !!winrate,
            winrateColor: getWinRateColor(winrate * 100),
            onClick: () => {
              aramBenchSwap(id);
              sendInteractionEvent("champ-select-aram-bench-swap");
            },
          });
        });

        return benchList;
      },
      rerollButton() {
        if (!inGameState.currentState || !benchEnabled) return null;
        const {
          currentState: { rerollsRemaining = 0 },
        } = inGameState;

        return {
          rerollsRemaining,
          hasRerolls: Boolean(rerollsRemaining),
          icon: Dice.svg,
          text: t("lol:aram.reroll", "Re-roll"),
          tooltip: null,
          tooltipPlacement: "bottom",
          onClick: () => {
            if (!rerollsRemaining) return;
            aramReroll();
            sendInteractionEvent("champ-select-aram-reroll", { donate: false });
          },
        };
      },
      rerollAndKeep() {
        if (!inGameState.currentState || !benchEnabled) return null;
        const {
          currentState: {
            summonersByCellId,
            localPlayerCellId,
            rerollsRemaining = 0,
          },
        } = inGameState;
        const localPlayer = summonersByCellId[localPlayerCellId];

        return {
          rerollsRemaining,
          hasRerolls: Boolean(rerollsRemaining),
          icon: Dice.svg,
          text: t("lol:aram.donateReroll", "Donate Re-roll"),
          tooltip: `
            <div>
              <p class="type-subtitle--bold color-shade1">
              ${t(
                "lol:aram.rerollAndKeepTooltipDescription",
                "Use a re-roll for your teammates, but keep your current champion so it doesn't get stolen.",
              )}
              </p>
            </div>
          `,
          tooltipPlacement: "bottom",
          onClick: () => {
            if (!rerollsRemaining) return;
            aramRerollAndKeep(localPlayer.championId);
            sendInteractionEvent("champ-select-aram-reroll", { donate: true });
          },
        };
      },
    });
  },

  buildChanges() {
    if (!inGameState.currentState || !derivedState.dynamicBuildChanges)
      return null;

    const {
      runes = [],
      items = [],
      summonerSpells = [],
    } = derivedState.dynamicBuildChanges;

    if (!runes.length && !items.length && !summonerSpells.length) return null;

    return {
      title: t(
        "lol:build.dynamic.dynamicBuildAdjustments",
        "Dynamic Build Adjustments",
      ),
      changeList: [...runes, ...items, ...summonerSpells],
    };
  },
});

const [inGameViewProxy, fragment] = !IS_NODE_BROWSERLESS
  ? s2(inGameView, template)
  : [];

globals.__BLITZ_DEV__.lolGameView = inGameViewProxy;

let container;

if (!IS_NODE_BROWSERLESS) {
  container = globals.document.createElement("div");
  container.classList.add("in-game-container");
  container.dataset.sticky = true; // show header when scrolling down
  container.appendChild(fragment);
}

export default inGameView;
export { container };
