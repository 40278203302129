export function patchVal(patch: string): number {
  if (!patch) return 0;
  const [maj, min] = patch.split(".");
  const majNum = Number(maj);
  const minNum = Number(min);
  return majNum * 100 + minNum; // ex: 14.1 -> 1401
}

export function isOldPatch(patch: string): boolean {
  return patchVal(patch) < patchVal("14.10");
}
