import { RunePageOptions } from "@/game-lol/components/ChampionBuilds.style.jsx";
import { cssRuneTreeContainer } from "@/game-lol/components/in-game-template-runes.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  {{#title}}
  <button
    class="section-title full-width type-body2-form--active"
    onclick="{{onClick}}"
    style="{{style}}"
  >
    <span class="text">{{text}}</span>
    <span class="icon">{{{icon}}}</span>
  </button>
  {{/title}} {{#runePages}}
  <div class="${RunePageOptions()}">
    {{#list}}
    <button
      onclick="{{onclick}}"
      data-selected="{{selected}}"
      style="{{style}}"
    >
      <div class="top">
        <div class="runes">
          <img src="{{keystoneImg}}" class="keystone" width="32" height="32" />
          <div class="secondary-tree">
            <img src="{{secondaryTreeImg}}" width="14" height="14" />
          </div>
        </div>
        <span class="winrate type-caption--semi">{{winrate}}</span>
      </div>
      <span class="games type-caption">{{games}}</span>
    </button>
    {{/list}}
  </div>
  {{/runePages}}
  <div class="${cssRuneTreeContainer()}">
    <div class="tree tree-primary" style:--tree-color="{{primaryColor}}">
      <div class="flex align-center gap-sp-1">
        {{#runeTreesLeft}}
        <div class="rune-tree-img" data-active="{{active}}">
          <img
            src="{{image}}"
            class="rune-img"
            data-active="{{active}}"
            width="18"
            height="18"
            onclick="{{selectTree}}"
          />
        </div>
        {{/runeTreesLeft}}
      </div>
      {{#primaryRows}}
      <div class="tree-row" data-keystones="{{keystoneRow}}">
        {{#row}}
        <div class="rune-container" data-highlight="{{highlight}}">
          <img
            src="{{image}}"
            class="rune-img"
            data-keystone="{{isKeystone}}"
            data-active="{{active}}"
            data-tooltip="{{tooltip}}"
            width="32"
            height="32"
            onclick="{{selectRune}}"
          />
        </div>
        {{/row}}
      </div>
      {{/primaryRows}}
    </div>
    <div class="tree tree-secondary" style:--tree-color="{{secondaryColor}}">
      <div class="flex align-center gap-sp-1">
        {{#runeTreesRight}}
        <div class="rune-tree-img" data-active="{{active}}">
          <img
            src="{{image}}"
            class="rune-img"
            data-active="{{active}}"
            width="18"
            height="18"
            onclick="{{selectTree}}"
          />
        </div>
        {{/runeTreesRight}}
      </div>
      <div class="flex column align-center gap-sp-4">
        {{#secondaryRows}}
        <div class="tree-row">
          {{#row}}
          <div class="rune-container" data-highlight="{{highlight}}">
            <img
              src="{{image}}"
              class="rune-img"
              data-active="{{active}}"
              data-tooltip="{{tooltip}}"
              width="32"
              height="32"
              onclick="{{selectRune}}"
            />
          </div>
          {{/row}}
        </div>
        {{/secondaryRows}}
      </div>
      <div class="flex column align-center gap-sp-2 shards">
        {{#shards}}
        <div class="tree-row">
          {{#row}}
          <div class="rune-container" data-highlight="{{highlight}}">
            <img
              src="{{image}}"
              class="rune-img shard"
              data-active="{{active}}"
              data-tooltip="{{tooltip}}"
              width="32"
              height="32"
              onclick="{{selectRune}}"
            />
          </div>
          {{/row}}
          <div>{{/shards}}</div>
        </div>
      </div>
    </div>
  </div>
`;

export default template;
