import html from "@/util/html-template.mjs";

const template = html`
  {{#title}}
  <button
    class="section-title type-body2-form--active"
    onclick="{{onClick}}"
    style="{{style}}"
  >
    <span class="text">{{text}}</span>
    <span class="icon">{{{icon}}}</span>
  </button>
  {{/title}}
  <div class="groups">
    {{#groups}}
    <div class="group">
      <p class="group-title type-caption--bold">{{title}}</p>
      <ol class="list" data-ordered="{{ordered}}">
        {{#list}}
        <li class="list-item">
          <figure
            class="item-image"
            data-tooltip="{{tooltip}}"
            data-place="{{placement}}"
          >
            <img src="{{image}}" width="28" height="28" loading="lazy" />
          </figure>
          <svg viewBox="0 0 6 8" class="chevron">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.61804 0L0.666626 0.94L3.75704 4L0.666626 7.06L1.61804 8L5.66663 4L1.61804 0Z"
            />
          </svg>
        </li>
        {{/list}}
      </ol>
    </div>
    {{/groups}}
  </div>
`;

export default template;
