const template = /* html */ `
  <svg
    width="24"
    height="24"
    style="display: none;"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="blitz-bolt">
      <path
        d="M10.7556 22C9.92706 22 9.28143 21.3096 9.3697 20.518L9.9967 14.8952C10.0202 14.6841 9.84808 14.5 9.62712 14.5L4.87877 14.5C4.66068 14.5 4.45359 14.408 4.31235 14.2483L3.81955 13.6913C3.59201 13.434 3.58294 13.0589 3.79782 12.7918L12.4242 2.06936C12.4594 2.02561 12.5137 2 12.5713 2H13.2287C14.0573 2 14.7029 2.69037 14.6147 3.48196L13.9877 9.10481C13.9641 9.3159 14.1363 9.5 14.3573 9.5H19.1093C19.3253 9.5 19.5305 9.59023 19.6718 9.74721L20.1759 10.3077C20.4073 10.565 20.4179 10.9433 20.2012 11.2122L11.56 21.9308C11.5248 21.9744 11.4705 22 11.4131 22H10.7556Z"
      />
    </g>
    <g id="stat-damage">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8061 6.6781C16.2344 6.06046 15.6157 5.46721 15.0205 4.89965C14.2828 4.20757 13.5447 3.49211 12.8778 2.72526C12.6867 2.50258 12.4016 2.37906 12.0915 2.40343C11.8064 2.42781 11.5198 2.60173 11.3772 2.84878C10.3294 4.52907 10.3059 6.90077 11.3055 9.02509C11.4007 9.22306 11.4963 9.39567 11.5906 9.59364C11.7096 9.8407 11.8286 10.0877 11.9477 10.3104C12.2331 10.9771 12.2092 11.6936 11.8759 12.2625C11.5188 12.8801 10.4071 13.2016 9.66939 13.0781C9.07419 12.9792 8.33646 12.5596 8.14537 11.6949C8.12188 11.5714 8.12188 11.3991 8.12188 11.2008C8.16886 10.4353 8.24092 9.59496 8.31266 8.92858C8.33646 8.50892 8.38472 8.13834 8.40821 7.86559C8.4555 7.34711 8.48026 7.02529 8.12315 6.77823C7.90856 6.63033 7.62318 6.60596 7.40891 6.72948C6.95624 6.97653 6.59912 7.34711 6.29025 7.66894L6.24327 7.71769C3.81518 10.263 3.29078 14.3881 5.00558 17.5006C6.26676 19.9945 9.2034 21.6004 11.9648 21.6004C12.0604 21.6004 12.1794 21.6004 12.2749 21.6004C15.1319 21.4769 18.0682 19.5749 19.2824 16.9064C19.9494 15.4241 20.1627 13.7435 19.8776 12.1877C19.5675 10.3335 18.5431 8.55536 16.8061 6.6781Z"
      />
    </g>
    <g id="stat-cc">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9368 21C5.05922 21 2.00105 16.5675 2.00105 12.1912C1.96695 10.0087 2.76275 7.8825 4.22931 6.24C5.8664 4.45125 8.20834 3 10.9368 3C14.7112 3 17.6102 4.58625 19.5315 6.70125C22.4192 9.87375 21.9872 14.1712 21.9644 14.3625C21.8735 14.925 21.3505 15.3187 20.7707 15.2512C20.1909 15.195 19.7703 14.7 19.793 14.1262C19.793 14.0587 20.1227 10.5487 17.8831 8.11875C16.4052 6.4875 14.0632 5.14875 10.9368 5.14875C6.2643 5.14875 4.18384 8.95125 4.18384 12.2025C4.18384 15.4537 6.29841 18.8512 10.9368 18.8512C12.608 18.8512 13.9836 18.3562 14.9045 17.4C15.7117 16.5337 16.1437 15.3975 16.1209 14.2275C16.1209 11.9888 14.8476 9.53625 12.0623 9.53625C8.61762 9.53625 8.14013 11.8537 8.11739 12.27C8.11739 12.3037 8.04918 13.4738 8.71993 14.1713C9.17468 14.5875 9.78859 14.8012 10.4025 14.745C12.051 14.745 12.267 13.6763 12.2897 13.215C12.3124 12.6413 12.7899 12.18 13.3811 12.18H13.3925C13.9836 12.1912 14.4611 12.675 14.4611 13.26C14.4611 14.7225 13.347 16.8937 10.4025 16.8937C9.16331 16.95 7.9696 16.4887 7.10558 15.6225C5.82092 14.25 5.91187 12.36 5.93461 12.1463C5.96872 11.6513 6.63947 7.37625 12.0623 7.37625C16.2346 7.37625 18.3037 10.9875 18.3037 14.205C18.3265 15.9262 17.6784 17.5912 16.4847 18.8512C15.5525 19.8412 13.8358 21 10.9368 21Z"
      />
    </g>
    <g id="stat-heal">
      <path
        d="M11.9995 20.4996L3.83365 11.9881C1.96824 10.0438 1.98617 6.96881 3.87413 5.04634C5.82731 3.05747 9.03205 3.05747 10.9852 5.04635L11.9995 6.07918L13.0138 5.04635C14.967 3.05747 18.1718 3.05747 20.1249 5.04634C22.0129 6.96881 22.0308 10.0438 20.1654 11.9881L11.9995 20.4996Z"
      />
    </g>
    <g id="stat-tank">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2C15.5642 2.01284 18.8907 3.21594 21.637 5.40418L22 5.723L20.9286 16.0909L12 22L3.07143 16.0909L2 5.723L2.35593 5.40418C5.10258 3.21669 8.43601 2.01369 12 2ZM12.8333 19.3863L19.2619 15.1817L19.9762 6.43174C17.6386 4.75874 14.912 3.85204 12 3.8181C9.08642 3.8433 6.35704 4.75089 4.02379 6.43174L4.73808 15.1817L11.1666 19.3863L11.1666 15.9999H9.97617L9.97617 14.5227H14.0238V15.9999H12.8333L12.8333 19.3863ZM9.61912 8.18398V5.97739C9.61936 5.97734 9.61937 5.97732 9.61912 5.97732V5.97739L9.61071 5.97842C9.40416 6.00268 5.54906 6.45546 6.16674 10.0682C6.40819 12.017 7.95063 13.4918 9.97626 13.7046V12.9091H14.0239V13.7046C14.3513 13.7046 17.1037 13.2839 17.8334 10.0682C18.4948 7.16025 14.8887 6.12452 14.4282 5.99225C14.4009 5.98442 14.3847 5.97976 14.381 5.97806V5.97732C14.3799 5.97732 14.38 5.97757 14.381 5.97806L14.381 8.18398L9.61912 8.18398ZM11.9999 4.99995L13.4285 6.70449H10.5714L11.9999 4.99995Z"
      />
    </g>
    <g id="stat-ap">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6421 11.6704C21.5345 9.77793 21.4424 6.60669 19.4069 4.59143C17.3714 2.57616 14.2107 2.4534 12.3081 4.34591L4.19676 12.4684L8.42122 12.3661L3 21L11.633 15.5782L11.541 19.7929L19.6421 11.6704Z"
      />
    </g>
    <g id="stat-ad">
      <path
        d="M12.6498 3H14.783V5.17079L3 17.1618V12.8202L12.6498 3ZM9.20873 12.8675L14.2368 7.77132C14.2368 7.77132 18.9375 7.77574 20.3799 4.24045C22.412 12.204 19.8517 21.4 6.65678 20.9865C8.3328 19.1672 9.51347 15.3587 9.20873 12.8675Z"
      />
    </g>
    <g id="stat-true">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.998 1L14.033 9.56408L23 12.0368L14.0698 14.6201L11.998 23L9.92607 14.6201L1 12.0368L9.96292 9.56408L11.998 1ZM3 3L7.25 4.15422V7.25H4.15422L3 3ZM19.8458 7.25L21 3L16.75 4.15422V7.25H19.8458ZM3 21L4.15422 16.75H7.25V19.8428L3 21ZM16.75 19.8428L21 21L19.8458 16.75H16.75V19.8428Z"
      />
    </g>
    <g id="role-all">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3841 10.6475L20.6338 10.6475C21.3883 10.6475 22 11.253 22 12C22 12.747 21.3883 13.3525 20.6338 13.3525L14.3841 13.3525L17.7175 18.9737C18.0965 19.6128 17.8804 20.4351 17.2348 20.8103C17.2323 20.8118 17.2298 20.8132 17.2273 20.8146L17.2214 20.818C16.5712 21.1903 15.7393 20.9733 15.3591 20.332L12 14.6677L8.64093 20.332C8.26065 20.9733 7.4288 21.1903 6.77862 20.818L6.77273 20.8146C6.12469 20.4435 5.90324 19.6226 6.2781 18.9811C6.27955 18.9786 6.28101 18.9761 6.28249 18.9737L9.61593 13.3525L3.36624 13.3525C2.61169 13.3525 2 12.747 2 12C2 11.253 2.61169 10.6475 3.36624 10.6475L9.61593 10.6475L6.28249 5.02634C5.90347 4.38721 6.11958 3.56492 6.76519 3.1897C6.7677 3.18824 6.77021 3.18679 6.77273 3.18535L6.77862 3.18198C7.4288 2.80965 8.26065 3.02675 8.64093 3.668L12 9.33233L15.3591 3.668C15.7393 3.02675 16.5712 2.80965 17.2214 3.18198L17.2273 3.18535C17.8753 3.55645 18.0968 4.37735 17.7219 5.01888C17.7204 5.02137 17.719 5.02386 17.7175 5.02634L14.3841 10.6475Z"
      />
    </g>
    <g id="role-top">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 3H19.6026L16.2984 6.58974H6.58974V16.2984L3 19.6026L3 3Z"
      />
      <g opacity="0.35">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.5 20.5H4.79492L8.57537 17.359H17.359V8.18907L20.5 4.79492V20.5ZM9.73083 9.73082H14.218V14.218H9.73083V9.73082Z"
        />
      </g>
    </g>
    <g id="role-jungle">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1575 22L6.25126 16.0341C6.16126 12.9205 5.01376 9.93182 3 7.56818C8.19752 9.52273 9.03002 13.4318 9.03002 13.4318C9.96377 8.86364 6.48751 2 6.48751 2C16.4663 14.4886 12.1575 22 12.1575 22ZM12.4275 9.75C13.6875 6.90909 15.3975 4.28409 17.5013 2C15.7913 5.67045 14.6775 9.60227 14.2275 13.625C14.2275 13.625 13.4625 10.9318 12.4275 9.75ZM21 7.4091C14.0137 11.5909 14.7562 19.0455 14.7562 19.0455L17.895 15.8523C17.7825 9.77274 21 7.4091 21 7.4091Z"
      />
    </g>
    <g id="role-mid">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 20V16.7273L16.7273 4H20V7.27273L7.27273 20H4Z"
      />
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5455 4L11.6364 6.90909H6.90909V11.6364L4 14.5455V4H14.5455ZM9.45455 20L12.3636 17.0909H17.0909V12.3636L20 9.45455V20H9.45455Z"
      />
    </g>
    <g id="role-adc">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.499 20.5H3.89648L7.20068 16.9103H16.9093V7.20163L20.499 3.89743V20.5Z"
      />
      <path
        opacity="0.35"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 3H18.7051L14.9247 6.14103H6.14103V14.8623L3 18.2564V3ZM13.7692 13.7692H9.28205V9.28205H13.7692V13.7692Z"
      />
    </g>
    <g id="role-support">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2728 3.5H9.72732L8.93187 4.5625L12 8.34028L15.0682 4.5625L14.2728 3.5ZM8.36364 7.1597H2C2.87549 8.01781 3.88568 8.82542 4.95455 9.40275C5.38323 9.54135 5.75545 9.63748 6.20455 9.63886H7.90909L6.09091 11.8819L9.04546 13.2986L10.1818 9.04859L8.36364 7.1597ZM15.6363 7.1597H21.9999C21.1242 8.01666 20.1141 8.82643 19.0454 9.40275C18.6167 9.54135 18.2445 9.63748 17.7954 9.63886H16.0908L17.909 11.8819L14.9545 13.2986L13.8181 9.04859L15.6363 7.1597ZM14.3863 18.6111L12.5681 9.16666C12.4905 9.43505 12.2691 9.61258 11.9999 9.63888C11.7319 9.61129 11.5088 9.434 11.4317 9.16666L9.61353 18.6111L11.9999 20.5L14.3863 18.6111Z"
      />
    </g>
    <g id="role-missing">
      <path
        fill-opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5218 15.2622C13.0898 15.0621 13.4646 14.5223 13.4516 13.9233L13.4682 13.2709C13.8612 12.7325 14.3128 12.239 14.815 11.7994L14.8535 11.7618C16.0654 10.5818 17.4167 9.26612 17.4167 7.65417C17.4167 5.24582 15.7823 2.83331 12.0798 2.83331C8.3774 2.83331 6.90143 5.73495 6.60957 7.35986C6.48458 7.99838 6.81537 8.63899 7.4101 8.91017C7.80099 9.07653 8.24728 9.05386 8.61909 8.84877C8.9909 8.64368 9.24644 8.27921 9.31135 7.86143C9.52166 6.51183 10.7022 5.52402 12.0757 5.54841C13.7351 5.54841 14.6482 6.29869 14.6482 7.65417C14.6482 8.12409 13.5144 9.21296 12.899 9.80392L12.8887 9.81382L12.8824 9.81987C11.809 10.8498 10.7996 11.8183 10.7081 13.0098V13.9648C10.7079 14.4051 10.9209 14.8186 11.2804 15.0757C11.6399 15.3329 12.1019 15.4023 12.5218 15.2622ZM9.91668 19.0833C9.91668 20.2339 10.8494 21.1666 12 21.1666C13.1506 21.1666 14.0833 20.2339 14.0833 19.0833C14.0833 17.9327 13.1506 17 12 17C10.8494 17 9.91668 17.9327 9.91668 19.0833Z"
      />
    </g>
    <g id="search">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9594 15.9594C16.3499 15.5689 16.9831 15.5689 17.3736 15.9594L20.7069 19.2927C21.0975 19.6833 21.0975 20.3164 20.7069 20.7069C20.3164 21.0975 19.6833 21.0975 19.2927 20.7069L15.9594 17.3736C15.5689 16.9831 15.5689 16.3499 15.9594 15.9594Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3333 5C7.83553 5 5 7.83553 5 11.3333C5 14.8311 7.83553 17.6667 11.3333 17.6667C14.8311 17.6667 17.6667 14.8311 17.6667 11.3333C17.6667 7.83553 14.8311 5 11.3333 5ZM3 11.3333C3 6.73096 6.73096 3 11.3333 3C15.9357 3 19.6667 6.73096 19.6667 11.3333C19.6667 15.9357 15.9357 19.6667 11.3333 19.6667C6.73096 19.6667 3 15.9357 3 11.3333Z"
      />
    </g>
    <g id="cancel">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM12.0002 14.0571C11.6083 14.0571 11.2768 14.333 10.6138 14.885L9.31189 15.9689C8.95058 16.2697 8.41956 16.2455 8.08709 15.9131C7.75456 15.5807 7.73031 15.0496 8.03116 14.6882L9.11507 13.3863C9.66697 12.7234 9.94291 12.3919 9.94291 12C9.94291 11.608 9.66697 11.2766 9.11507 10.6137L8.03116 9.31177C7.73031 8.9504 7.75456 8.41927 8.08709 8.08682C8.41956 7.75442 8.95058 7.73024 9.31189 8.03104L10.614 9.11509C11.2769 9.66698 11.6083 9.94292 12.0003 9.94292C12.3922 9.94293 12.7236 9.66699 13.3865 9.11511L14.6881 8.03155C15.0494 7.73076 15.5804 7.75495 15.9128 8.08733C16.2454 8.41979 16.2696 8.95094 15.9687 9.3123L14.8851 10.6137C14.3331 11.2766 14.0571 11.608 14.0571 12C14.0571 12.392 14.333 12.7234 14.885 13.3864L15.9688 14.6882C16.2697 15.0496 16.2454 15.5807 15.9129 15.9131C15.5804 16.2455 15.0494 16.2697 14.6881 15.9688L13.3866 14.8851C12.7237 14.3331 12.3922 14.0571 12.0002 14.0571Z"
      />
    </g>
    <g id="ban">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0007 18.6667C8.31875 18.6667 5.33398 15.6819 5.33398 12C5.33398 10.6413 5.74046 9.37747 6.43845 8.32358L15.6771 17.5622C14.6232 18.2602 13.3594 18.6667 12.0007 18.6667ZM8.32403 6.43793L17.5627 15.6766C18.2608 14.6227 18.6673 13.3588 18.6673 12C18.6673 8.31811 15.6825 5.33334 12.0007 5.33334C10.6418 5.33334 9.37796 5.73987 8.32403 6.43793ZM12.0007 21.3333C6.84599 21.3333 2.66732 17.1547 2.66732 12C2.66732 6.84535 6.84599 2.66667 12.0007 2.66667C17.1553 2.66667 21.334 6.84535 21.334 12C21.334 17.1547 17.1553 21.3333 12.0007 21.3333Z"
      />
    </g>
    <g id="no-minion">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.97447 5.44266C5.24298 4.74135 4.08141 4.7507 3.36139 5.47072C3.17902 5.65309 3.17902 5.94878 3.36139 6.13115L5.69263 8.46239C5.68801 8.51165 5.68566 8.56042 5.68566 8.60866V15.4947L4.95898 15.3902C4.82403 15.3693 4.68908 15.4215 4.59565 15.526C4.50222 15.6305 4.47107 15.7768 4.50222 15.9126C4.84479 17.2188 6.2151 20.2595 10.5959 21.0119C10.5959 21.0119 8.91419 20.2491 9.10105 13.8228C9.10105 13.8228 6.90825 13.1531 7.05445 9.82421L11.2396 14.0093V16.8949H13.5026V16.2724L15.4135 18.1833C14.9861 20.6541 14.1463 21.0328 14.1463 21.0328C15.3796 20.8209 16.3744 20.4286 17.1747 19.9445L18.2211 20.9909C18.9506 21.7204 20.1334 21.7204 20.8628 20.9909C21.0405 20.8133 21.0451 20.5282 20.8767 20.3449L5.97447 5.44266ZM19.0773 15.2433V8.60866C19.0773 6.69645 15.4231 3.99011 13.4923 2.93474C12.7967 2.54812 11.9662 2.54812 11.2707 2.93474C10.5272 3.33708 9.52828 3.98883 8.58081 4.74684L14.4063 10.5723C15.6437 9.31542 17.6447 9.2983 17.6447 9.2983C17.8617 11.1808 17.3665 12.3154 16.8142 12.9802L19.0773 15.2433Z"
      />
    </g>
    <g id="chevron-down">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4792 10.43C19.7678 10.1378 19.7678 9.66786 19.4792 9.37571L18.6529 8.53941C18.3596 8.24247 17.8802 8.24221 17.5865 8.53883L12 14.1808L6.41353 8.53883C6.11982 8.2422 5.64044 8.24247 5.34706 8.53941L4.52079 9.37571C4.23215 9.66786 4.23216 10.1378 4.52079 10.43L12 18L19.4792 10.43Z"
      />
    </g>
    <g id="checkmark">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6886 6.35845C20.9815 6.65135 20.9815 7.12622 20.6886 7.41911L9.33333 18.7744L3.5336 12.9747C3.2407 12.6818 3.2407 12.2069 3.5336 11.914L4.35855 11.0891C4.65145 10.7962 5.12632 10.7962 5.41921 11.0891L9.33333 15.0032L18.803 5.53349C19.0959 5.2406 19.5708 5.2406 19.8637 5.53349L20.6886 6.35845Z"
      />
    </g>
    <g id="caret-down">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 9L12 15L18 9H6Z" />
    </g>
    <g id="chevron-down">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6959 10.2798C20.1014 9.87315 20.1014 9.21894 19.6959 8.81225L19.1955 8.31027C18.7835 7.8969 18.1101 7.89653 17.6976 8.30946L12 14.0125L6.30239 8.30946C5.88986 7.89653 5.21654 7.89689 4.80446 8.31027L4.30406 8.81225C3.89865 9.21893 3.89865 9.87315 4.30406 10.2798L12 18L19.6959 10.2798Z"
      />
    </g>
    <g id="checkmark">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 16.1701L4.83 12.0001L3.41 13.4101L9 19.0001L21 7.00009L19.59 5.59009L9 16.1701Z"
      />
    </g>
    <g id="spinner">
      <path
        d="M4 12C4 13.5822 4.46919 15.129 5.34824 16.4446C6.22729 17.7602 7.47672 18.7855 8.93853 19.391C10.4003 19.9965 12.0089 20.155 13.5607 19.8463C15.1126 19.5376 16.538 18.7757 17.6569 17.6569C18.7757 16.538 19.5376 15.1126 19.8463 13.5607C20.155 12.0089 19.9965 10.4003 19.391 8.93853C18.7855 7.47672 17.7602 6.22729 16.4446 5.34824C15.129 4.46919 13.5823 4 12 4L12 6.74896C13.0386 6.74896 14.0538 7.05693 14.9173 7.63392C15.7809 8.21091 16.4539 9.03101 16.8513 9.99051C17.2488 10.95 17.3528 12.0058 17.1501 13.0244C16.9475 14.043 16.4474 14.9787 15.713 15.713C14.9787 16.4474 14.043 16.9475 13.0244 17.1501C12.0058 17.3528 10.95 17.2488 9.99051 16.8513C9.03101 16.4539 8.21091 15.7809 7.63392 14.9173C7.05693 14.0538 6.74896 13.0386 6.74896 12L4 12Z"
        fill="white"
      />
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="2s"
        repeatCount="indefinite"
      />
    </g>
  </svg>
`;

export default template;
