import { cssDmgBreakdown } from "@/game-lol/components/in-game-template-damage.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  <div
    class="${cssDmgBreakdown()}"
    data-can-click="{{canClick}}"
    data-is-small="{{isSmall}}"
    onclick="{{onClick}}"
    data-tip="{{tooltip}}"
  >
    <div class="text">
      {{#title}}
      <p class="type-subtitle--bold title">{{text}}</p>
      {{/title}} {{#subtitle}}
      <p class="type-subtitle--bold subtitle" style="{{style}}">{{text}}</p>
      {{/subtitle}}
    </div>
    <div class="flex">
      <div class="flex-item" style="{{apStyles}}">
        <div class="bar type-subtitle--bold">{{apPercentText}}</div>
      </div>
      <div class="flex-item" style="{{adStyles}}">
        <div class="bar type-subtitle--bold">{{adPercentText}}</div>
      </div>
      <div class="flex-item" style="{{trueStyles}}">
        <div class="bar type-subtitle--bold">{{truePercentText}}</div>
      </div>
    </div>
  </div>
`;

export default template;
