import { css } from "goober";

export const cssContainer = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--sp-3);
  padding-bottom: var(--sp-3);
  border-bottom: 1px solid var(--shade6);

  [data-queue-id="1700"] & {
    margin: 0;
    padding: var(--sp-3);
  }

  .section-subtitle {
    color: var(--shade1);
  }

  .preferred-bans-list {
    --ban-color: var(--red);
    --radii: var(--br-lg);
    display: flex;
    gap: var(--sp-1);

    .champ-img {
      width: var(--sp-12);
      background: var(--shade9);
      border-radius: var(--radii);
    }

    .close-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity var(--transition);

      svg {
        fill: var(--ban-color);
        width: var(--sp-8);
        height: var(--sp-8);
      }
    }
    .warning-icon {
      position: absolute;
      top: -10%;
      right: -7%;

      svg {
        width: var(--sp-6);
        height: var(--sp-6);
      }
    }
    li {
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--ban-color);
        border-radius: var(--radii);
        opacity: 0;
        transition: opacity var(--transition);
        mix-blend-mode: hue;
      }

      &[data-empty="true"],
      &[data-teammate="true"] {
        cursor: default;

        .close-icon {
          display: none;
        }
      }
      &[data-empty="true"] {
        pointer-events: none;
        opacity: 0.38;

        &::after {
          content: unset;
        }
        img {
          opacity: 0;
        }
      }
      &[data-teammate="true"] {
        img {
          opacity: 0.38;
        }
      }
      &:not([data-teammate="true"]):hover {
        &::after {
          opacity: 1;
        }
        .champ-img {
          opacity: 0.5;
          box-shadow: 0 0 0 2px var(--ban-color);
        }
        .close-icon {
          opacity: 1;
        }
      }
    }
  }
`;
