import { css } from "goober";

export const cssContainer = () => css`
  --gap: var(--sp-1);
  --size: var(--sp-4);
  --radii: var(--br-sm);
  --color: var(--shade7);
  --image: initial;

  /* ~ SKILL ORDER ~ */
  display: grid;
  grid-template-columns: repeat(19, var(--size));
  grid-column-gap: var(--gap);

  .col {
    display: grid;
    grid-template-rows: repeat(5, var(--size));
    grid-row-gap: var(--gap);
    grid-gap: var(--gap);
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color);
    font-weight: 700;
    position: relative;

    span {
      position: relative;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: var(--shade7);
      background-image: var(--image);
      background-position: center;
      background-size: cover;
      border-radius: var(--radii);
      overflow: hidden;
      z-index: 0;
      transition: background-color 0.2s ease-in-out;
    }

    &:first-child {
      color: var(--shade2);
    }

    &:not(:first-child) {
      &::before {
        background-color: var(--shade9);
      }
    }

    &[data-active="true"] {
      span {
        opacity: 1;
      }
      &:not(:first-child):before {
        background-color: var(--shade10);
      }
    }
  }
`;

export const cssSkillOrderTitle = () => css`
  display: flex;
  align-items: center;
  gap: var(--sp-4);

  .skill {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color);
    background-color: var(--shade10);
    height: var(--sp-5);
    width: var(--sp-5);
    border-radius: var(--br-sm);

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      color: var(--shade1);
      transform: translate(200%, -50%);
    }

    &:last-child {
      svg {
        display: none;
      }
    }
  }
`;

export const cssSkillOrder = () => css`
  display: flex;
  gap: var(--sp-4);
`;
