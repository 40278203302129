import { cssButton, cssButtonGrp } from "clutch/src/Button/Button.jsx";
import { cssToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import {
  cssBuildSettings,
  cssOverlaySettings,
} from "@/game-lol/components/in-game-template-settings.style.mjs";
import {
  BUILDS_SETTINGS_ID,
  OVERLAY_SETTINGS_ID,
} from "@/game-lol/utils/in-game-settings.mjs";
import html from "@/util/html-template.mjs";

const css = {
  button: cssButton(),
  buttonGroup: cssButtonGrp(),
  toggleSwitch: cssToggleSwitch(),
  overlaySettings: cssOverlaySettings(),
  buildSettings: cssBuildSettings(),
};

const template = html`
  {{#overlaySettings}}
  <dialog
    id="${OVERLAY_SETTINGS_ID}"
    class="${css.overlaySettings}"
    onclick="{{onDialogClick}}"
  >
    <h4 class="type-h5">{{title}}</h4>
    <p class="type-body2 subtitle">{{subtitle}}</p>
    <div class="settings-list">
      <div>
        {{#itemDifference}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/itemDifference}}
      </div>
      <div>
        {{#summonerSpellTracker}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/summonerSpellTracker}}
      </div>
      <div>
        {{#benchmarking}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/benchmarking}}
      </div>
      <div>
        {{#skillRecommended}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
              {{#conflictDisclaimer}}
              <p class="type-caption disclaimer">{{text}}</p>
              {{/conflictDisclaimer}}
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/skillRecommended}}
      </div>
      <div>
        {{#skillTracker}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/skillTracker}}
      </div>
      <div>
        {{#junglePath}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
              {{#conflictDisclaimer}}
              <p class="type-caption disclaimer">{{text}}</p>
              {{/conflictDisclaimer}}
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/junglePath}}
      </div>
      <div>
        {{#minimapTimer}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/minimapTimer}}
      </div>
      <div>
        {{#trinketReminder}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/trinketReminder}}
      </div>
      <div>
        {{#healthTimer}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/healthTimer}}
      </div>
      <div>
        {{#loadingScreen}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="100" height="57" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/loadingScreen}}
      </div>
    </div>
    <div class="controls">
      {{#close}}
      <button
        onclick="{{onClick}}"
        data-emphasis="{{emphasis}}"
        class="${css.button} type-form--button"
      >
        {{text}}
      </button>
      {{/close}} {{#save}}
      <button
        onclick="{{onClick}}"
        data-emphasis="{{emphasis}}"
        class="${css.button} type-form--button"
      >
        {{text}}
      </button>
      {{/save}}
    </div>
  </dialog>
  {{/overlaySettings}} {{#buildSettings}}
  <dialog
    id="${BUILDS_SETTINGS_ID}"
    class="${css.buildSettings}"
    onclick="{{onDialogClick}}"
  >
    <h4 class="type-h5">{{title}}</h4>
    <p class="type-body2 subtitle">{{subtitle}}</p>
    <div class="settings-list">
      <div>
        {{#summonerSpells}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="40" height="40" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{#flashPosition}}
        <div class="setting child">
          <div class="info">
            <img src="{{img}}" width="40" height="40" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <div class="summoner-selections">
            <button onclick="{{setLeft}}" data-active="{{leftActive}}">
              <img src="{{img}}" width="32" height="32" />
              <span class="type-caption">{{leftText}}</span>
            </button>
            <button onclick="{{setRight}}" data-active="{{rightActive}}">
              <img src="{{img}}" width="32" height="32" />
              <span class="type-caption">{{rightText}}</span>
            </button>
          </div>
        </div>
        {{/flashPosition}} {{/summonerSpells}}
      </div>
      <div>
        {{#runes}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="40" height="40" class="round" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/runes}}
      </div>
      <div>
        {{#items}}
        <div class="setting">
          <div class="info">
            <img src="{{img}}" width="40" height="40" />
            <div>
              <label class="type-form--button title">{{title}}</label>
              <p class="type-caption description">{{description}}</p>
            </div>
          </div>
          <button
            onclick="{{toggle}}"
            class="${css.toggleSwitch}"
            data-active="{{isActive}}"
            data-disabled="{{isDisabled}}"
          >
            <span class="inner"></span>
          </button>
        </div>
        {{/items}}
      </div>
    </div>
    <div class="controls">
      {{#close}}
      <button
        onclick="{{onClick}}"
        data-emphasis="{{emphasis}}"
        class="${css.button} type-form--button"
      >
        {{text}}
      </button>
      {{/close}} {{#save}}
      <button
        onclick="{{onClick}}"
        data-emphasis="{{emphasis}}"
        class="${css.button} type-form--button"
      >
        {{text}}
      </button>
      {{/save}}
    </div>
  </dialog>
  {{/buildSettings}}
`;

export default template;
