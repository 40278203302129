import { preferredBans } from "@/game-lol/components/in-game-template-preferred-bans.mjs";
import {
  suggestionDuoItem,
  suggestionItem,
} from "@/game-lol/components/in-game-template-suggestions.mjs";
import { cssSearch } from "@/game-lol/components/in-game-template-suggestions.style.mjs";
import { cssContainer } from "@/game-lol/components/in-game-template-suggestions-arena.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  <div
    class="cols suggestions ${cssContainer()}"
    data-loading="{{loading}}"
    data-suggestions-phase="{{suggestionsPhase}}"
  >
    {{#favoriteBans}} ${preferredBans} {{/favoriteBans}}
    <div class="columns">
      <div>
        {{#columnMeta}}
        <div class="group" data-active="{{active}}">
          <header class="title">
            <h4 class="type-subtitle2">{{title}}</h4>
          </header>
          <div
            class="${cssSearch()} search-container"
            data-search-active="{{searchActive}}"
          >
            <svg viewBox="0 0 24 24" class="search-icon">
              <use href="#search" />
            </svg>
            <svg
              viewBox="0 0 24 24"
              class="cancel-icon"
              onclick="{{clearSearch}}"
            >
              <use href="#cancel" />
            </svg>
            <input
              type="text"
              placeholder="{{searchPlaceholder}}"
              class="search-input"
              value="{{searchText}}"
              oninput="{{handleSearch}}"
            />
          </div>
          <ol class="suggestions-list">
            {{#list}} ${suggestionItem} {{/list}}
          </ol>
        </div>
        {{/columnMeta}}
      </div>

      <div>
        {{#columnWithTeammate}}
        <div class="group" data-active="{{active}}">
          <header class="title">
            <h4 class="type-subtitle2">{{title}}</h4>
          </header>
          <div
            class="${cssSearch()} search-container"
            data-search-active="{{searchActive}}"
          >
            <svg viewBox="0 0 24 24" class="search-icon">
              <use href="#search" />
            </svg>
            <svg
              viewBox="0 0 24 24"
              class="cancel-icon"
              onclick="{{clearSearch}}"
            >
              <use href="#cancel" />
            </svg>
            <input
              type="text"
              placeholder="{{searchPlaceholder}}"
              class="search-input"
              value="{{searchText}}"
              oninput="{{handleSearch}}"
            />
          </div>
          <ol class="suggestions-list">
            {{#list}} ${suggestionItem} {{/list}}
          </ol>
          {{#waiting}}
          <div class="waiting">
            {{{icon}}}
            <p class="type-body2">{{text}}</p>
          </div>
          {{/waiting}}
        </div>
        {{/columnWithTeammate}}
      </div>

      <div>
        {{#columnDuos}}
        <div class="group" data-active="{{active}}">
          <header class="title">
            <h4 class="type-subtitle2">{{title}}</h4>
          </header>
          <div
            class="${cssSearch()} search-container"
            data-search-active="{{searchActive}}"
          >
            <svg viewBox="0 0 24 24" class="search-icon">
              <use href="#search" />
            </svg>
            <svg
              viewBox="0 0 24 24"
              class="cancel-icon"
              onclick="{{clearSearch}}"
            >
              <use href="#cancel" />
            </svg>
            <input
              type="text"
              placeholder="{{searchPlaceholder}}"
              class="search-input"
              value="{{searchText}}"
              oninput="{{handleSearch}}"
            />
          </div>
          <ol class="suggestions-list">
            {{#list}} ${suggestionDuoItem} {{/list}}
          </ol>
        </div>
        {{/columnDuos}}
      </div>
    </div>
  </div>
`;

export default template;
